import { storageMock } from '../../tests/fixtures/localStorage'

export class NamedStorage {
  prefix: string;
  storage: Storage

  constructor(prefix: string, storage: Storage) {
    this.prefix = `${prefix}/`
    this.storage = storage
  }

  get(key: string, fallback= "") {
    const value = this.storage.getItem(`${this.prefix}${key}`)
    return value ? value : fallback;
  }

  set(key: string, value: string) {
    this.storage.setItem(`${this.prefix}${key}`, value)
  }

  del(key: string) {
    this.storage.removeItem(`${this.prefix}${key}`)
  }

  clear() {
    let i = 0
    while (i < this.storage.length) {
      const currentKey = this.storage.key(i)
      if (currentKey && currentKey.startsWith(this.prefix)) {
        this.storage.removeItem(currentKey)
      } else {
        i++
      }
    }
  }

  dump(state: Record<string, string>) {
    // write every property of state in cache
    for (const [key, value] of Object.entries(state)) {
      this.set(key, value)
    }
  }

  update(state: Record<string, string>) {
    // update every property of state from cache
    for (const key in state) {
      const value = this.get(key)
      if (value !== "") state[key] = value
    }
  }
}

const userStorage = import.meta.env.NODE_ENV === 'test'
    ? storageMock()
    : localStorage

export const settingsStorage = new NamedStorage('settings', userStorage)
export const tokenStorage = new NamedStorage('token', userStorage)
