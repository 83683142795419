<script setup lang="ts">
import type { ExtraLinks, Links } from '#/layout';
import { computed } from 'vue'
import { useLayoutStore } from '@/stores/layout'
import { useContribStore } from '@/stores/contrib';
import { useI18n } from 'vue-i18n'
import { toSvg } from 'jdenticon'

const layoutStore = useLayoutStore()
const { t } = useI18n()
const contribStore = useContribStore()

const navDrawer = computed({
  get() {
    return layoutStore.navDrawer
  },
  set(value) {
    layoutStore.setNavDrawer(value)
  },
})
const links = computed<Links>(() => {
  const links = [
    { name: 'home', label: t('menu.home'), icon: 'mdi-home', to: { name: 'Home' } },
    { name: 'help', label: t('menu.help'), icon: 'mdi-help', href: 'https://community.pages.unistra.fr/pount/pount-api/index.html' },
  ]
  if (contribStore.isLogged) {
    links.splice(1, 0, { name: 'dashboardProjects', label: t('dashboard.label'), icon: 'mdi-monitor-dashboard', to: { name: 'dashboardProjects' } })
  }
  return links
})
const extraLinks = computed<ExtraLinks>(() => layoutStore.extraLinks)

const image = (name='') =>  toSvg(name, 25)
</script>

<template>
  <q-drawer
    v-model="navDrawer"
    side="left"
    overlay
    behavior="mobile"
    bordered
  >
    <q-list
      dense
      padding
    >
      <q-item
        v-for="link in links"
        :key="`navDrawer-link-${link.name}`"
        v-bind="link"
      >
        <q-item-section avatar>
          <q-icon :name="link.icon" />
        </q-item-section>
        <q-item-section>
          {{ link.label }}
        </q-item-section>
      </q-item>
    </q-list>
    <template v-if="extraLinks.links?.length">
      <q-separator />
      <q-list dense>
        <q-item>
          <q-item-section avatar>
            <q-avatar square>
              <div v-html='image(extraLinks.title)' />
            </q-avatar>
          </q-item-section>
          <q-item-section class="text-weight-medium">
            {{ extraLinks.title }}
          </q-item-section>
        </q-item>
        <q-item
          v-for="extraLink in extraLinks.links"
          :key="`extra-link-${extraLink.name}`"
          :to="extraLink.to"
        >
          <q-item-section avatar />
          <q-item-section>
            {{ extraLink.label }}
          </q-item-section>
        </q-item>
      </q-list>
    </template>
  </q-drawer>
</template>
