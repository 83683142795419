import { defineStore } from "pinia"
import type {
  LayoutStoreState,
  ExtraLinks
} from '#/layout'

export const useLayoutStore = defineStore('Layout', {
  state: (): LayoutStoreState => ({
    extraLinks: {
      title: '',
      links: [],
    },
    toolbarInset: false,
    navDrawer: false,
  }),
  getters: {
  },
  actions: {
    setExtraLinks(extraLinks: ExtraLinks) {
      this.extraLinks = extraLinks
    },
    setToolbarInset(toolbarInset: boolean) {
      this.toolbarInset = toolbarInset
    },
    setNavDrawer(navDrawer: boolean) {
      this.navDrawer = navDrawer
    },
  },
})
