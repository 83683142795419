import { useProjectStore } from '@/stores/project'
import { useTemplateStore } from '@/stores/template'
import { RouterView, type RouteLocation, type RouteRecordRaw } from 'vue-router'
import { useSetStore } from '@/stores/set'
import { useItemStore } from '@/stores/item'
import { useCommunityStore } from '@/stores/community'
import { useGroupStore } from '@/stores/group'
import { useReferentialStore } from '@/stores/referential'

export const routes: RouteRecordRaw[] = [
  // Static pages
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/statics/about.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/statics/contact.vue'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/statics/help.vue'),
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('@/views/statics/legal.vue'),
  },
  // Public routes
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomePage.vue'),
  },
  // TEST ROUTE
  // ...import.meta.env.DEV ? [
  //   {
  //     path: '/rtir',
  //     name: 'rtir',
  //     component: () => import('@/components/renderer/file/rti/RtiRelight.vue'),
  //   },
  //   {
  //     path: '/hopla',
  //     name: 'hopla',
  //     component: () => import('@/components/renderer/file/model/Hopla.vue'),
  //   },
  // ] : [],
  // TEST ROUTE FIN
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/SearchResult.vue'),
    props: (route: RouteLocation) => ({
      query: Object.entries(route.query)
        .map(([name, value]) => `${name}=${value}`)
        .join('&')
    }),
  },
  {
    path: '/invitation',
    name: 'invitation',
    component: () => import('@/views/signin/InvitationForm.vue'),
  },
  {
    path: '/login/:from',
    name: 'login',
    component: () => import('@/views/signin/LoginPage.vue'),
    props: true,
  },
  {
    path: '/reset-password',
    name: 'reset',
    component: () => import('@/views/signin/ResetLogin.vue'),
  },
  {
    path: '/login/validate',
    name: 'handshake',
    component: () => import('@/views/signin/HandShake.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/statics/logout.vue'),
  },
  {
    path: '/p/projects/:projectId',
    name: 'projectPublic',
    props: true,
    component: () => import('@/views/projects/ProjectPublic.vue'),
    beforeEnter: async (to) => {
      const store = useCommunityStore()
      await store.loadProject(to.params.projectId as string)
    }
  },
  {
    path: '/p/sets/:setId',
    name: 'setPublic',
    props: true,
    component: () => import('@/views/sets/SetPublic.vue'),
  },
  {
    path: '/p/items/:itemId',
    name: 'itemPublic',
    props: true,
    component: () => import('@/views/items/ItemPublic.vue'),
    beforeEnter: async (to) => {
      const store = useCommunityStore()
      await store.loadItem(to.params.itemId as string);
    }
  },
  {
    path: '/embed/sets/:setId',
    name: 'embedSet',
    props: (route: RouteLocation) => ({
      embed: true,
      ...route.params,
      ...route.query,
    }),
    component: () => import('@/views/sets/SetPublic.vue'),
    meta: {
      layout: 'Without',
    },
  },
  {
    path: '/embed/items/:itemId',
    name: 'embedItem',
    props: (route: RouteLocation) => {
      return {
        embed: true,
        ...route.params,
        ...route.query,
      };
    },
    component: () => import('@/views/items/ItemPublic.vue'),
    meta: {
      layout: 'Without',
    },
    beforeEnter: async (to) => {
      const store = useCommunityStore()
      await store.loadItem(to.params.itemId as string);
    }
  },
  // Authenticated Routes
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ProfilePage.vue'),
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/dashboard/',
    name: 'dashboard',
    component: () => import('@/views/dashboard/DashboardPage.vue'),
    meta: {
      requiresAuthentication: true,
    },
    children: [
      {
        path: 'projects',
        name: 'dashboardProjects',
        component: () => import('@/views/dashboard/DashboardProjects.vue'),
      },
      {
        path: 'referentials',
        name: 'dashboardReferentials',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'dashboardReferentialsList',
            component: () => import('@/views/dashboard/DashboardReferentials.vue'),
          },
          {
            path: 'create',
            name: 'standardReferentialCreate',
            component: () => import('@/views/dashboard/DashboardReferentialsCreate.vue'),
            beforeEnter: () => {
              const store = useReferentialStore()
              store.$reset()
            },
          },
          {
            path: ':referentialId/:mode?',
            name: 'dashboardReferentialsDetail',
            component: () => import('@/views/dashboard/DashboardReferentialsDetail.vue'),
            props: route => {
              const {projectId, referentialId, mode} = route.params
              return {
                projectId,
                referentialId,
                readonly: mode !== 'edit'
              }
            },
            beforeEnter: async (to) => {
              const referentialStore = useReferentialStore()
              await referentialStore.referentialLoad(to.params.referentialId as string)
            },
          },
        ],
      },
      {
        path: 'search',
        name: 'dashboardSearch',
        component: () => import('@/views/dashboard/DashboardSearch.vue'),
        props: (route: RouteLocation) => ({
          query: Object.entries(route.query)
            .map(([name, value]) => `${name}=${value}`)
            .join('&')
        }),
      },
    ],
  },
  {
    path: '/projects/create',
    name: 'projectCreate',
    component: () => import('@/views/projects/ProjectCreate.vue'),
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/projects/:projectId',
    component: () => import('@/views/projects/ProjectPage.vue'),
    props: (route: RouteLocation) => ({
      welcome: route.query.welcome === 'true',
    }),
    meta: {
      requiresAuthentication: true,
      context: 'project',
    },
    beforeEnter: async (to) => {
      const { loadProject } = useProjectStore()
      await loadProject(to.params.projectId as string);
    },
    children: [
      {
        path: 'detail',
        name: 'projectDetail',
        component: () => import('@/views/projects/ProjectDetail.vue'),
      },
      {
        path: 'detail/edit',
        name: 'projectEdit',
        component: () => import('@/views/projects/ProjectEdit.vue'),
      },
      {
        path: 'sets',
        name: 'projectSets',
        component: () => import('@/views/projects/ProjectSets.vue'),
        children: [
          {
            path: '',
            name: 'projectSetsList',
            component: () => import('@/views/projects/ProjectSetsList.vue'),
          },
          {
            path: 'create',
            name: 'setCreate',
            component: () => import('@/views/sets/SetCreate.vue'),
            meta: {
              managerOnly: true,
            },
            beforeEnter: async () => {
              const { loadPublicTemplates, loadProjectTemplates } = useTemplateStore()
              await Promise.all([loadPublicTemplates(), loadProjectTemplates()])
            },
          },
        ],
      },
      {
        path: 'templates',
        name: 'projectTemplates',
        component: () => import('@/views/projects/ProjectTemplates.vue'),
        meta: {
          managerOnly: true
        },
        children: [
          {
            path: '',
            name: 'projectTemplatesList',
            component: () => import('@/views/projects/ProjectTemplatesList.vue'),
          },
          {
            path: 'create',
            name: 'projectTemplateCreate',
            component: () => import('@/views/templates/TemplateCreate.vue'),
            beforeEnter: async () => {
              const { loadPublicTemplates, loadProjectTemplates } = useTemplateStore()
              await Promise.all([loadPublicTemplates(), loadProjectTemplates()])
            },
          },
          {
            path: ':templateId',
            name: 'projectTemplate',
            component: () => import('@/views/templates/TemplatePage.vue'),
            beforeEnter: async (to) => {
              const templateStore = useTemplateStore()
              await templateStore.loadTemplate(to.params.templateId as string)
            },
          },
        ],
      },
      {
        path: 'referentials/',
        name: 'projectReferentials',
        component: () => import('@/views/projects/ProjectReferentials.vue'),
        meta: {
          isManagerOnly: true,
        },
        children: [
          {
            path:'',
            name: 'projectReferentialsList',
            component: () => import('@/views/projects/ProjectReferentialsList.vue'),
          },
          {
            path: 'create',
            name: 'projectReferentialCreate',
            props: true,
            component: () => import('@/views/projects/ProjectReferentialsCreate.vue'),
            beforeEnter: () => {
              const store = useReferentialStore()
              store.$reset()
            },
          },
          {
            path: ':referentialId/:mode',
            name: 'projectReferentialDetail',
            props: route => {
              const {projectId, referentialId, mode} = route.params
              return {
                projectId,
                referentialId,
                readonly: mode !== 'edit'
              }
            },
            component: () => import('@/views/projects/ProjectReferentialsDetail.vue'),
            beforeEnter: async (to) => {
              const referentialStore = useReferentialStore()
              await referentialStore.referentialLoad(to.params.referentialId as string)
            },
          },
        ]
      },
      {
        path: 'members',
        name: 'projectMembers',
        component: () => import('@/views/projects/ProjectMembers.vue'),
        meta: {
          managerOnly: true
        },
        beforeEnter: async () => {
          const groupStore = useGroupStore()
          await groupStore.loadProjectGroups()
        }
      },
      {
        path: 'groups',
        name: 'projectGroups',
        component: () => import('@/views/projects/ProjectGroups.vue'),
        meta: {
          managerOnly: true
        },
        beforeEnter: async () => {
          const groupStore = useGroupStore()
          await groupStore.loadProjectGroups()
        }
      },
      {
        path: 'search',
        name: 'projectSearch',
        component: () => import('@/views/projects/ProjectSearch.vue'),
        props: (route: RouteLocation) => ({
          query: Object.entries(route.query)
            .map(([name, value]) => `${name}=${value}`)
            .join('&')
        }),
      },
    ],
  },
  {
    path: '/sets/:setId',
    props: true,
    component: () => import('@/views/sets/SetPage.vue'),
    meta: {
      requiresAuthentication: true,
      context: 'set',
    },
    beforeEnter: async (to) => {
      const setStore = useSetStore();
      await setStore.loadSet(to.params.setId as string);
    },
    children: [
      {
        path: 'detail',
        name: 'setDetail',
        component: () => import('@/views/sets/SetDetail.vue'),
      },
      {
        path: 'detail/edit',
        name: 'setEdit',
        component: () => import('@/views/sets/SetEdit.vue'),
        meta: {
          managerOnly: true
        },
      },
      {
        path: 'items/',
        name: 'setItems',
        component: () => import('@/views/sets/SetItems.vue'),
        children: [
          {
            path: '',
            name: 'setItemsList',
            component: () => import('@/views/sets/SetItemsList.vue'),
          },
          {
            path: 'items/import',
            name: 'setItemImport',
            component: () => import('@/views/items/ItemCsvImport.vue'),
          },
          {
            path: 'create',
            name: 'setItemCreate',
            component: () => import('@/views/items/ItemCreate.vue'),
            beforeEnter: async () => {
              const setStore = useSetStore()
              if (!setStore.canContribute) return false

              const templateStore = useTemplateStore()
              await templateStore.loadTemplate(setStore.templateId)
            }
          },
        ],
      },
      {
        path: 'template/:templateId',
        name: 'setTemplate',
        component: () => import('@/views/templates/TemplatePage.vue'),
        meta: {
          managerOnly: true
        },
        beforeEnter: async () => {
          const setStore = useSetStore()
          const templateStore = useTemplateStore()
          await templateStore.loadTemplate(setStore.templateId)
        }
      },
      {
        path: 'search/settings',
        name: 'setSearchSettings',
        component: () => import('@/views/sets/SetSearchSettings.vue'),
        meta: {
          managerOnly: true
        },
      },
      {
        path: 'search',
        name: 'setSearch',
        component: () => import('@/views/sets/SetSearch.vue'),
        props: (route: RouteLocation) => ({
          query: Object.entries(route.query)
            .map(([name, value]) => `${name}=${value}`)
            .join('&')
        }),
      },
    ],
  },
  {
    path: '/items/:itemId/',
    props: true,
    component: () => import('@/views/items/ItemPage.vue'),
    meta: {
      requiresAuthentication: true,
      context: 'item',
    },
    beforeEnter: async (to) => {
      const itemStore = useItemStore()
      await itemStore.itemLoad(to.params.itemId as string);
    },
    children: [
      {
        path: 'detail',
        name: 'itemDetail',
        props: true,
        component: () => import('@/views/items/ItemDetail.vue'),
      },
      {
        path: 'detail/edit',
        name: 'itemEdit',
        props: true,
        component: () => import('@/views/items/ItemEdit.vue'),
        meta: {
          managerOnly: true
        },
      },
      {
        path: 'viewer',
        name: 'itemViewer',
        props: true,
        component: () => import('@/views/items/ItemViewer.vue'),
        beforeEnter: async () => {
          const { templateId } = useItemStore();
          const { loadTemplate } = useTemplateStore();
          await loadTemplate(templateId);
        }
      },
      {
        path: 'metadata',
        name: 'itemMetadata',
        component: () => import('@/views/items/ItemMetadata.vue'),
        meta: {
          managerOnly: true
        },
        beforeEnter: async () => {
          const { templateId } = useItemStore();
          const { loadTemplate } = useTemplateStore();
          await loadTemplate(templateId);
        }
      },
      {
        path: 'files',
        name: 'itemFiles',
        props: true,
        component: () => import('@/views/items/ItemFiles.vue'),
        meta: {
          managerOnly: true
        },
      },
      {
        path: 'copy',
        name: 'itemCopy',
        props: true,
        component: () => import('@/views/items/ItemCopy.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    name: 'pageNotFound',
    component: () => import('@/views/statics/PageNotFound.vue'),
  }
]

export default routes
