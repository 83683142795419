import { defineStore } from 'pinia'
import {
  deleteGroup,
  deleteGroupSet,
  deleteGroupUser,
  patchGroup,
  postAddGroupSet,
  postAddGroupUser,
  postGroup
} from '@/services/groupsService'
import { getProjectGroups } from '@/services/projectsService'
import { useProjectStore } from '@/stores/project'
import { useContribStore } from './contrib'
import { t } from '@/plugins/i18n'
import { notifyError, notifySuccess, notifyWarning } from '@/services/alertService'

import type { GroupFormData, GroupStoreState, GroupUser, ProjectGroup } from '#/group'


/** Put at start project members, managers then other groups */
export const groupSort = (g1: Partial<ProjectGroup>, g2: Partial<ProjectGroup>) => g1.isDefaultGroup
    ? (g2.name === "Members" ? 1 : -1)
    : (g2.isDefaultGroup ? 1 : 0)

export const useGroupStore = defineStore('Group', {
  state: (): GroupStoreState => ({
    projectGroups: [],
  }),
  getters: {
    getGroupByName: (state) => (name: string) => state.projectGroups.find((group) => group.name === name),
  },
  actions: {
    /** Create a group for current project and add it to project groups. */
    createGroup(group: GroupFormData) {
      const projectStore = useProjectStore()
      const projectId = projectStore.projectId as string
      return postGroup({ projectId, ...group })
        .then((newGroup) => {
          this.projectGroups = [
            ...this.projectGroups,
            newGroup,
          ]
          notifySuccess(t('notify.success.group.create'))
        })
        .catch((error) => {
          notifyError(t('notify.error.groups.create'), error)
        })
    },
    /** Remove the group of given id from project groups */
    deleteGroup(groupId: number) {
      return deleteGroup(groupId)
        .then(() => {
          const groupIndex = this.projectGroups.findIndex((group) => group.id === groupId)
          this.projectGroups.splice(groupIndex,1)
          notifySuccess(t('notify.success.group.delete'))
        })
        .catch((error) => {
          notifyError(t('notify.error.groups.delete'), error)
        })
    },
    /**
     * Retrieve groups details of current project
     * and load it into project groups, default groups at start
     *
     * @returns {Promise<void | *>}
     */
    async loadProjectGroups() {
      const projectStore = useProjectStore()
      const projectId = projectStore.projectId
      try {
        const groups = await getProjectGroups(projectId)
        // put default groups Members, Managers, at start
        groups.sort(groupSort)
        this.projectGroups = groups
      } catch (error) {
        notifyError(t('notify.error.groups.all'), error as Error)
      }
    },
    /** Update name and/or description of group with given id */
    async updateGroup(groupData: Partial<ProjectGroup>) {
      try {
        const group = await patchGroup(groupData)
        const groupIndex = this.projectGroups.findIndex((projectGroup) => projectGroup.id === group.id)
        this.projectGroups.splice(groupIndex,1 , group)
        notifySuccess(t('notify.updated.group'))
      } catch (error) {
        notifyError(t('notify.error.update'), error as Error)
      }
    },
    /** Add given user to given group and update project manager list if needed */
    async addGroupUser({ groupId, userId }: { groupId: number, userId: string }) {
      await postAddGroupUser(groupId, userId)
          .then((group) => {
            const groupIndex = this.projectGroups.findIndex((projectGroup) => projectGroup.id === group.id)
            this.projectGroups.splice(groupIndex, 1, group)
            if (group.name === 'Managers') {
              const projectStore = useProjectStore()
              projectStore.updateCurrentManagers(group.users)
            }
          })
          .catch((error) => {
            notifyError(t('notify.error.groups.user.add'), error)
          })
    },
    addProjectMember(user: GroupUser) {
      const memberGroup = this.getGroupByName('Members')
      if (memberGroup) {
        memberGroup.users.push(user)
      }
      else {
        notifyError(t('group.members.missing'), new Error(t('error.internal')))
      }
    },
    /**
     * Remove user of given id from given group.
     * For project Managers,
     *   user is removed from project manager list.
     * If user as an email, notify email sent status
     */
    async removeGroupUser({ groupId, userId }: {groupId: number, userId: string}) {
      await deleteGroupUser(groupId, userId )
          .then(({ group, email }) => {
            const groupIndex = this.projectGroups.findIndex((projectGroup) => projectGroup.id === group.id)
            this.projectGroups.splice(groupIndex,1, group)
            if (group.name === 'Managers') {
              const projectStore = useProjectStore()
              const contribStore = useContribStore()
              projectStore.updateCurrentManagers(group.users)
              if (userId === contribStore.myId) {
                projectStore.current.isManager = false
                this.router.push({ name: 'projectSets', params: { projectId: projectStore.projectId } })
              }
            }
            if (email === 'sent') {
              notifySuccess(t('group.members.mail.sent'))
            } else if (email === 'error') {
              notifyWarning(t('group.members.mail.error'))
            }
          })
          .catch((error) => {
            notifyError(t('notify.error.groups.user.remove'), error)
          })
    },
    /** Add contribution right on given set, to the group of given id */
    async addGroupSet({ groupId, setId }: { groupId: number, setId: string }) {
      await postAddGroupSet(groupId, setId)
          .catch((error) => {
            notifyError(t('notify.error.groups.set.add'), error)
          })
    },
    /** Remove contribution right on set of given id */
    async removeGroupSet({ groupId, setId }: { groupId: number, setId: string }) {
      await deleteGroupSet(groupId, setId)
          .catch((error) => {
        notifyError(t('notify.error.groups.set.remove'), error)
      })
    },
  },
})
