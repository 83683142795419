<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout'
import ULayoutDefaultHeaderToolbarMobileSignature from './ULayoutDefaultHeaderToolbarMobileSignature.vue'
import ULayoutDefaultHeaderToolbarButtons from './ULayoutDefaultHeaderToolbarButtons.vue'
import ULayoutDefaultHeaderToolbarButtonAppearance from './ULayoutDefaultHeaderToolbarButtonAppearance.vue'
import ULayoutDefaultHeaderToolbarButtonLanguage from './ULayoutDefaultHeaderToolbarButtonLanguage.vue'
import ULayoutDefaultHeaderToolbarButtonProfile from './ULayoutDefaultHeaderToolbarButtonProfile.vue'
import ULayoutDefaultHeaderToolbarButtonLogin from './ULayoutDefaultHeaderToolbarButtonLogin.vue'
import NotificationMenu from '@/components/menu/notifications/HeaderMenu.vue'

type Props = {
  authenticated?: boolean
}
withDefaults(defineProps<Props>(), {
  authenticated: false,
})

const layoutStore = useLayoutStore()
const onSearchButtonClick = () => {
  const toolbarInset = !layoutStore.toolbarInset
  layoutStore.setToolbarInset(toolbarInset)
}
const onOpenNavDrawer = () => {
  layoutStore.setNavDrawer(true)
}
</script>

<template>
  <q-btn
    class="q-mr-sm"
    color="primary"
    dense
    flat
    icon="mdi-menu"
    round
    @click="onOpenNavDrawer"
  />
  <u-layout-default-header-toolbar-mobile-signature />
  <q-space />
  <u-layout-default-header-toolbar-buttons mobile>
    <q-btn
      color="primary"
      dense
      flat
      icon="mdi-magnify"
      round
      @click="onSearchButtonClick"
    />
    <u-layout-default-header-toolbar-button-appearance v-if="!authenticated" />
    <u-layout-default-header-toolbar-button-language v-if="!authenticated" />
    <NotificationMenu v-if="authenticated" />
    <u-layout-default-header-toolbar-button-profile v-if="authenticated" />
    <u-layout-default-header-toolbar-button-login
      v-else
      mobile
    />
  </u-layout-default-header-toolbar-buttons>
</template>
