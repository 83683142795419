import { setCssVar } from 'quasar'

type BrandColorName = 'primary' | 'secondary' | 'accent' | 'positive' | 'negative' | 'info' | 'warning'
type BrandColors = Record<BrandColorName, string>

export const lightBrandColors: BrandColors = {
  primary: '#f68212',
  secondary: '#0086a8',
  accent: '#1df5aa',
  positive: '#1da878',
  negative: '#f53305',
  info: '#2a60f5',
  warning: '#fab505',
}

// jungle green: #419d78
// maize:        #fce762
// delft blue    #2b3a67
//
export const darkBrandColors: BrandColors = {
  primary: '#f68212',
  secondary: '#0086a8',
  accent: '#1df5aa',
  positive: '#1da878',
  negative: '#f53305',
  info: '#2a60f5',
  warning: '#fab505',
}

export const setBrandColors = (brandColors: BrandColors) => {
  (Object.keys(brandColors) as BrandColorName[]).forEach((brandColorName) => {
    setCssVar(brandColorName, brandColors[brandColorName])
  })
}
