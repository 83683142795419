<script setup lang="ts">
import { useQuasar } from 'quasar'
import { computed, toRefs } from 'vue'

const quasar = useQuasar()

const props = defineProps<{
  modelValue: boolean,
  title: string,
}>()
const { modelValue, title } = toRefs(props)

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void
  (e: 'click-back'): void 
}>()

const showMenu = computed({
  get: (): boolean => {
    return modelValue.value
  },
  set: (value: boolean) => {
    emit('update:modelValue', value)
  }
})

const onGoBack = () => {
  showMenu.value = false
  emit('click-back')
}
</script>

<template>
  <q-menu
    v-model="showMenu"
    square
    :class="{
      'bg-elevation-8': quasar.dark.isActive
    }"
  >
    <q-list style="min-width: 200px">
      <q-item clickable @click="onGoBack">
        <q-item-section avatar>
          <q-icon name="mdi-chevron-left" />
        </q-item-section>
        <q-item-section>
          {{ title }}
        </q-item-section>
      </q-item>
      <q-separator />
      <slot />
    </q-list>
  </q-menu>
</template>
