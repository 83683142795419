<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const DEFAULT_LAYOUT = 'Default';

const route = useRoute()

const layout = computed<string>(() => `ULayout${route.meta.layout || DEFAULT_LAYOUT}`);

// TODO: Wait for the Vue3 version of formbuilder plugin
// this.$store.state.formBuilder.controls.controlsGroups.layouts.color = 'secondary'
</script>

<style lang="scss">
@import "assets/style/app.scss";
</style>
