import { authAxios } from '@/services/authService'
import type { GroupFormData, ProjectGroup } from '#/group'

export const postGroup = async (groupData: GroupFormData) => {
  const response = await authAxios.post('groups/create_for_project/', groupData)

  return response.data
}

export const getGroup = async (groupId: string) => {
  const response = await authAxios.get(`groups/${groupId}/`)

  return response.data
}

export const deleteGroup = async (groupId: number) => {
  return await authAxios.delete(`groups/${groupId}/`)
}

export const patchGroup = async ({ id, ...groupUpdate }: Partial<ProjectGroup>) => {
  const response = await authAxios.patch(`groups/${id}/`, groupUpdate)

  return response.data
}

export const postAddGroupUser = async (groupId: number, userId: string) => {
  const response = await authAxios.post(`groups/${groupId}/add_user/`, { userId })

  return response.data
}

export const deleteGroupUser = async (groupId: number, userId: string) => {
  const response = await authAxios.delete(`groups/${groupId}/remove_user/`, { data: { userId } })

  return response.data
}

export const postAddGroupSet = async (groupId: number, setId: string) => {
  return await authAxios.post(`groups/${groupId}/add_set/`, { setId })
}

export const deleteGroupSet = async (groupId: number, setId: string) => {
  return await authAxios.delete(`groups/${groupId}/remove_set/`, { data: { setId } })
}
