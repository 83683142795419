import { publicAxios, authAxios } from '@/services/authService'
import type { NewItem, PountItem } from '#/item'
import type { MediaFile, RendererName } from '#/file'

export const getPublicItem = async (id: string) => {
  const response = await publicAxios.get(`items/${id}/`)

  return response.data
}

export const getPublicItemList = async (setId: string, page: number, size: number, itemIds?: string) => {
  const itemIdsQuery = itemIds ? `&item_ids=${itemIds}` : ''
  const response = await publicAxios.get(`items/?set_id=${setId}${itemIdsQuery}&page=${page}&page_size=${size}`)

  return response.data
}

export const deleteFile = async (fromItemId: string, fileId: string) => {
  return await authAxios.delete(`items/${fromItemId}/mediafiles/${fileId}/`)
}

export const postUrl = async (itemId: string, filename: string, contentType: string) => {
  const response = await authAxios.post(`items/${itemId}/post_url/`,{ filename, contentType })

  return response.data
}

export const postItemFile = async (itemId: string, filename: string, component: RendererName | null) => {
  const response = await authAxios.post(`items/${itemId}/mediafiles/`, { filename, component })

  return response.data
}

export const postItemRti = async (itemId: string, formData: FormData) => {
  const response = await authAxios.post(`items/${itemId}/mediafiles/upload_rti/`, formData)

  return response.data
}

export const postItem = async (itemData: NewItem) => {
  const response = await authAxios.post('items/', itemData)

  return response.data
}

export const deleteItem = async (itemId: string) => {
  const response = await authAxios.delete(`items/${itemId}/`)

  return response.data
}

export const postItemCsvImport = async (formData: FormData) => {
  const response = await authAxios.post(`items/import_csv/`, formData)

  return response.data
}

export const getItemXmlExport = async (itemId: string) => {
  const response = await authAxios.get(`items/${itemId}/export/datacite/`, {responseType: 'blob'})

  return response.data
}

export const deleteItems = async (itemIds: string[]) => {
  const response = await authAxios.delete(`items/`, { data: { itemIds } })

  return response.data
}

export const getItem = async (itemId: string) => {
  const response = await authAxios.get(`items/${itemId}/`)

  return response.data
}

export const searchItems = async (query: string, pageSize: number, page: number, ordering: string, filters={}) => {
  const formattedFilters = Object.entries(filters).reduce((acc, [filter, value]) => value ? `${acc}&metadata__${filter}=${value}` : acc, '')
  const response = await authAxios.get(`items/search/?query=${query}&page_size=${pageSize}&page=${page}&ordering=${ordering}${formattedFilters}`)

  return response.data
}

export const patchItem = async (itemId: string, itemData: Partial<PountItem>) => {
  const response = await authAxios.patch(`items/${itemId}/`, itemData)

  return response.data
}

export const getSetItems = async (setId: string, page: number, size: number) => {
  const response = await authAxios.get(`items/?set_id=${setId}&page=${page}&page_size=${size > 0 ? size : 0}`)

  return response.data
}

export const postItemThumbnail = async (objectId: string, thumbnailData: FormData) => {
  const response = await authAxios.post(`items/${objectId}/thumbnail/`, thumbnailData)

  return response.data.thumbnails
}

export const deleteItemThumbnail = async (objectId: string) => {
  return await authAxios.delete(`items/${objectId}/thumbnail/`)
}

export const patchMediaFile = async (itemId: string, mediaFileId: string, mediaPatch: Partial<MediaFile>): Promise<MediaFile> => {
  const response = await authAxios.patch(`items/${itemId}/mediafiles/${mediaFileId}/`, mediaPatch)
  return response.data
}
