import { defineStore } from 'pinia'
import { getPaginatedResult } from '@/stores/helpers'
import { getPublicProject, getPublicProjectList } from '@/services/projectsService'
import { getPublicSet, getPublicSetList } from '@/services/setsService'
import { getPublicItem, getPublicItemList } from '@/services/itemsService'
import { getPublicTemplate } from '@/services/templatesService'

import type { CommunityStoreState, LoadSetPayload, PublicItem, PublicProject, PublicSet } from '#/community'

const initState = (): CommunityStoreState => ({
  currentProject: {
    id: '',
    name: '',
    description: '',
    managers: [],
    sets: [],
    thumbnails: {},
  },
  currentTemplate: {
    id: '',
    name: '',
    fields: [],
    messages: {
      defaultLocale: '',
      locales: {}
    }
  },
  projectList: {
    pageContent: [],
    total: 0,
    pageCount: 1,
  },
  currentSet: {
    creator: {
      id: '',
      name: '',
    },
    description: '',
    id: '',
    items: [],
    project: {
      id: '',
      name: '',
    },
    publisher: '',
    thumbnails: {},
    title: '',
  },
  setList: {
    pageContent: [],
    total: 0,
    pageCount: 1,
  },
  currentItem: null,
  itemList: {
    pageContent: [],
    total: 0,
    pageCount: 1,
  },
})

export const useCommunityStore = defineStore('Community', {
  state: () => initState(),
  actions: {
    async loadProject(projectId: string) {
      const currentProject = await getPublicProject(projectId)
      const currentProjectHasSets = !!currentProject.sets.length
      this.currentProject = currentProject
      if (currentProjectHasSets) await this.loadSetList(projectId, )
    },
    async loadProjectList({ page=1, size=10 }) {
      const fetchedProjectList = await getPublicProjectList(page, size)
      this.projectList = getPaginatedResult<PublicProject>(fetchedProjectList, page, +size)
    },
    async loadSet({ setId, itemIds, size=8 }: LoadSetPayload) {
      const currentSet = await getPublicSet(setId)
      const currentSetHasItems = !!currentSet.items.length
      this.currentSet = currentSet
      if (currentSetHasItems) {
        const payload = { setId, size } as LoadSetPayload
        if (itemIds) payload.itemIds = itemIds
        await this.loadItemList(payload)
      }
    },
    async loadSetList(projectId: string, page=1, size=10) {
      const fetchedSetList = await getPublicSetList(projectId, page, size)
      this.setList = getPaginatedResult<PublicSet>(fetchedSetList, page, size)
    },
    async loadItem(itemId: string, withTemplate = true) {
      const currentItem = await getPublicItem(itemId)
      this.currentItem = currentItem
      if (withTemplate) await this.loadTemplate(currentItem.set.templateId)
    },
    async loadItemList({ setId, itemIds, page=1, size=10 }: LoadSetPayload) {
      const fetchedItemList = await getPublicItemList(setId, page, size, itemIds)
      this.itemList = getPaginatedResult<PublicItem>(fetchedItemList, page, size)
    },
    async loadTemplate(templateId: string) {
      this.currentTemplate = await getPublicTemplate(templateId)
    },
  },
})
