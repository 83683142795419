import { publicAxios, authAxios } from '@/services/authService'

import type { NewPountProject, PatchPountProject } from '#/project'
import type { ProjectGroup } from '#/group'

export const getPublicProject = async (id: string) => {
  const response = await publicAxios.get(`projects/${id}/`)

  return response.data
}

export const getPublicProjectList = async (page: number, size: number) => {
  const response = await publicAxios.get(`projects/?page=${page}&page_size=${size}`)

  return response.data
}

export const searchPublicProjects = async (query: string, pageSize: number, page: number, ordering: string) => {
  const response = await publicAxios.get(`projects/search/?query=${query}&page_size=${pageSize}&page=${page}&ordering=${ordering}`)

  return response.data
}

export const getProjectGroups = async (projectId: string): Promise<ProjectGroup[]> => {
  const response = await authAxios.get(`projects/${projectId}/groups/`)

  return response.data.groups
}

export const postProject = async (project: NewPountProject) => {
  const response = await authAxios.post('projects/', project)

  return response.data
}

export const getProject = async (projectId: string) => {
  const response = await authAxios.get(`projects/${projectId}/`)

  return response.data
}

export const requestProjectsDeletion = async (projectIds: string[]) => {
  const response = await authAxios.delete(`projects/request_deletion/`, { data: { projectIds } })

  return response.data
}

export const getProjects = async (page: number, size: number, manageOnly: boolean) => {
  const response = await authAxios.get(`projects/?page=${page}&page_size=${size > 0 ? size : 0}${manageOnly ? '&is_manager=true' : ''}`)

  return response.data
}

export const patchProject = async ({ id, ...project }: PatchPountProject) => {
  const response = await authAxios.patch(`projects/${id}/`, project)

  return response.data
}

export const postProjectThumbnail = async (objectId: string, thumbnailData: FormData) => {
  const response = await authAxios.post(`projects/${objectId}/thumbnail/`, thumbnailData)

  return response.data.thumbnails
}

export const deleteProjectThumbnail = async (objectId: string) => {
  const response = await authAxios.delete(`projects/${objectId}/thumbnail/`)

  return response.data
}

export const getProjectTemplates = async (projectId: string) => {
  const response = await authAxios.get(`projects/${projectId}/templates/`)

  return response.data
}

export const getProjectTemplatesList = async (projectId: string) => {
  const response = await authAxios.get(`projects/${projectId}/list_templates/`)

  return response.data
}

export async function patchBindReferential(projectId: string, referentialId: string) {
  const response = await authAxios.patch(`projects/${projectId}/referentials/${referentialId}/bind/`)

  return response.data
}

export async function patchUnbindReferential(projectId: string, referentialId: string) {
  await authAxios.delete(`projects/${projectId}/referentials/${referentialId}/unbind/`)
}
