import { publicAxios, authAxios} from '@/services/authService'
import type { ExternalInvitationData, InternalInvitationData, Password, UserInvitation, UserProfile } from '#/user'

export const postInvitedUser = async (userInvitation: UserInvitation) => {
  // const baseURL = import.meta.env.VITE_APP_AXIOS_BASE_URL
  const response = await authAxios.post('users/invite/signup/', userInvitation)

  return response.data
}

export const postExternalUserInvitation = async (invitationData: ExternalInvitationData) => {
  return await authAxios.post('users/invite/', invitationData)
}

export const postInternalUserInvitation = async (invitationData: InternalInvitationData) => {
  const response = await authAxios.post('users/invite/internal/', invitationData)

  return response.data
}

export const getProfile = async () => {
  const response = await authAxios.get(`users/profile/`)

  return response.data
}

export const patchProfile = async (profile: Partial<UserProfile>) => {
  const response = await authAxios.patch('users/profile/', profile)

  return response.data
}

export const patchEmail = async (email: string) => {
  const response = await authAxios.patch('users/profile/email/', {email})

  return response.data
}

export const patchPassword = (password: Password) => {
  return authAxios.patch('users/profile/password/', password)
}

export const postResetPassword =(resetPasswordData: {email: string, url: string}) => {
  return authAxios.post('login/password-reset/', resetPasswordData)
}

export const postInvitationValidation = async (invitationData: {token: string, oid: string}) => {
  const baseURL = import.meta.env.VITE_APP_AXIOS_BASE_URL
  const response = await publicAxios.post('users/invite/validate/', invitationData, { baseURL })

  return response.data
}

export const patchProfileLdapRefresh = () => {
  return authAxios.patch('/users/profile/ldap-refresh/')
}
