<script setup lang="ts">
import { computed } from 'vue'
import { useQuasar } from 'quasar'
import { useLayoutStore } from '@/stores/layout'
import { useContribStore } from '@/stores/contrib'
import ULayoutDefaultHeaderToolbar from './ULayoutDefaultHeaderToolbar.vue'
import ULayoutDefaultHeaderToolbarMobile from './ULayoutDefaultHeaderToolbarMobile.vue'
import ULayoutDefaultHeaderToolbarSearchBar from './ULayoutDefaultHeaderToolbarSearchBar.vue'

const quasar = useQuasar()
const isMobile = computed(() => quasar.screen.lt.md)
const isDark = computed(() => quasar.dark.isActive)

const layoutStore = useLayoutStore()
const contribStore = useContribStore()
</script>

<template>
  <q-header
    :class="{
      'bg-elevation-4': isDark,
      'bg-grey-2': !isDark,
    }"
    :bordered="!quasar.dark.isActive"
    height-hint="64"
  >
    <q-toolbar>
      <u-layout-default-header-toolbar-mobile
        v-if="isMobile"
        :authenticated="contribStore.isLogged"
      />
      <u-layout-default-header-toolbar
        v-else
        :authenticated="contribStore.isLogged"
      />
    </q-toolbar>
    <q-toolbar
      v-if="layoutStore.toolbarInset && isMobile"
      class="q-px-md"
      inset
    >
      <u-layout-default-header-toolbar-search-bar mobile />
    </q-toolbar>
  </q-header>
</template>

<style scoped lang="scss">
.q-header {
  color: var(--uni-text-color);
  .q-toolbar {
    height: 64px;
    &__title {
      display: flex;
      align-items: center;
    }
    svg {
      height: 53px;
    }
  }
}
</style>
