export const textTruncate = (content: string, size: number): string => content.length > size ? `${content.slice(0, size-1)}...` : content
export const stripHtml = (content: string): string => {
  const dummyDiv = document.createElement("DIV")
  dummyDiv.innerHTML = content

  return dummyDiv.textContent || dummyDiv.innerText || ""
}

export default {
  textTruncate,
  stripHtml,
}
