<script setup lang="ts">
import { ref } from 'vue'
import ULayoutDefaultHeaderToolbarButtonProfileMenu from './ULayoutDefaultHeaderToolbarButtonProfileMenu.vue'
import ULayoutDefaultUserAvatar from './ULayoutDefaultUserAvatar.vue'

// Data
const showProfileMenu = ref<boolean>(false)
</script>

<template>
  <q-btn
    round
    flat
  >
    <u-layout-default-user-avatar />
    <u-layout-default-header-toolbar-button-profile-menu v-model="showProfileMenu" />
  </q-btn>
</template>
