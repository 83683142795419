<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, toRefs } from 'vue'

defineEmits<{
  (e:'update:isOpen', opened:boolean): void
  (e:'cancel'): void
  (e:'confirm'): void
}>()

const props = withDefaults(
  defineProps<{
    isOpen?: boolean,
    title?: string
    object?: string,
    action?: string,
  }>(),
  {
    isOpen: false,
    object: 'delete',
    action: 'delete',
  },
)
const { action, isOpen, object } = toRefs(props)

const { t } = useI18n()

const content = computed<string>(() =>
  t(`confirm.${action.value}`, {object: object.value})
)
</script>

<template>
  <q-dialog
    v-model="isOpen"
  >
    <q-card style="min-width: 500px">
      <q-card-section class="text-h4">
        {{ title || t('confirm.title') }}
      </q-card-section>
      <q-card-section>
        <slot>{{ content }}</slot>
      </q-card-section>
      <q-card-actions>
        <q-space />
        <q-btn
          color="primary"
          outline
          square
          unelevated
          :label="t('btn.cancel')"
          @click="$emit('cancel')"
        />
        <q-btn
          color="primary"
          square
          unelevated
          :label="t('btn.confirm')"
          @click="$emit('confirm')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
