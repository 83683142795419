<script setup lang="ts">
import { useQuasar } from 'quasar'
import { useContribStore } from '@/stores/contrib'

const quasar = useQuasar()
const contribStore = useContribStore()
</script>

<template>
  <q-avatar
    color="primary"
    :text-color="quasar.dark.isActive ? 'black' : 'white'"
  >
    {{ contribStore.userFirstLetter }}
  </q-avatar>
</template>
