import type { AxiosResponse } from 'axios'
import type { PaginatedResult } from '#/community'

export function getUrlFromOrigin(relativePath: string): string {
  return `${window.location.origin}${relativePath}`
}

export function setNewLocation(newUrl: string) { // test purpose
  window.location.href = newUrl
}

export function getPaginatedResult<T>(responseData: AxiosResponse['data'], pageNumber: number, pageSize: number): PaginatedResult<T> {
  const {count, nextPage, results: pageContent} = responseData
  const pageCount = nextPage
      ? Math.ceil(count / (pageSize ? pageSize : pageContent.length) )
      : pageNumber // no next page, current page is last
  return { pageContent, pageCount, total: count }
}
