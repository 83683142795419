<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ULayoutDefaultHeaderToolbarButtonDropdown from './ULayoutDefaultHeaderToolbarButtonDropdown.vue'
import ULayoutDefaultHeaderToolbarListAppearance from './ULayoutDefaultHeaderToolbarListAppearance.vue'

const { t } = useI18n()
</script>

<template>
  <u-layout-default-header-toolbar-button-dropdown
    :access-label="t('appearance.selection')"
    icon="mdi-theme-light-dark"
  >
    <u-layout-default-header-toolbar-list-appearance />
  </u-layout-default-header-toolbar-button-dropdown>
</template>
