<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ULayoutDefaultHeaderToolbarButtonDropdown from './ULayoutDefaultHeaderToolbarButtonDropdown.vue'
import ULayoutDefaultHeaderToolbarListLanguage from './ULayoutDefaultHeaderToolbarListLanguage.vue'

const { t } = useI18n()
</script>

<template>
  <u-layout-default-header-toolbar-button-dropdown
    :access-label="t('language.selection')"
    icon="mdi-translate"
  >
    <u-layout-default-header-toolbar-list-language />
  </u-layout-default-header-toolbar-button-dropdown>
</template>
