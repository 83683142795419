import axios from 'axios'
import { tokenStorage as storage, settingsStorage } from '@/services/storageService'
import i18n from '@/plugins/i18n'

import type { AxiosInstance, HeadersDefaults, InternalAxiosRequestConfig } from 'axios'

const TOKEN_EXPIRATION_MARGIN = 2 * 1000

const baseURL = import.meta.env.VITE_APP_AXIOS_BASE_URL
const authBaseConfig = {
  baseURL,
}
const publicBaseConfig = {
  baseURL: baseURL.replace(/api(?=\/$)/, 'p'),
}

function setAcceptLanguage(config: InternalAxiosRequestConfig) {
  const { locale, fallbackLocale } = i18n.global
  const lang = settingsStorage.get('locale', locale.value)
  config.headers['Accept-Language'] = `${lang}, ${fallbackLocale.value};q=0.5`
  return config
}

export const publicAxios = axios.create(publicBaseConfig)
publicAxios.interceptors.request.use(setAcceptLanguage)

export class Token {
  name = Token
  private readonly body!: string
  private readonly expiration!: number | null

  constructor(body: string) {
    try {
      this.body = body
      this.expiration = JSON.parse(atob(body.split('.')[1])).exp * 1000
    } catch (error) {
      if (error instanceof DOMException
          && error.name === 'InvalidCharacterError') {
        this.body = 'invalid base64 token'
      }
      this.expiration = null
    }
  }

  get timeToExpiration() {
    return this.expiration
        ? this.expiration - Date.now()
        : -1
  }

  get isUp() {
    return this.timeToExpiration > TOKEN_EXPIRATION_MARGIN
  }

  toString() {
    return this.body
  }
}

const RETRY_RESET_INTERVAL_MS = import.meta.env.NODE_ENV === 'test' ? 100 : 1000
const RETRY_RESET_MAX = 3
const TIMEOUT_MIN = 5

export interface Credentials {
  ticket?: string
  username?: string
  password?: string
  newPassword?: string
  newPassword2?: string
  token?: string
  rid?: string
}

export class Authority {
  get: AxiosInstance['get']
  delete: AxiosInstance['delete']
  head: AxiosInstance['head']
  options: AxiosInstance['options']
  post: AxiosInstance['post']
  put: AxiosInstance['put']
  patch: AxiosInstance['patch']
  available: boolean
  private access!: Token | null
  private refresh!: Token | null
  private headers: HeadersDefaults['common']
  private nextAccessRefresh!: ReturnType<typeof setTimeout>
  private readonly instance: AxiosInstance

  constructor() {
    this.instance = axios.create(authBaseConfig)
    this.instance.interceptors.request.use(setAcceptLanguage)
    this.headers = this.instance.defaults.headers.common
    this.get = this.instance.get
    this.delete = this.instance.delete
    this.head = this.instance.head
    this.options = this.instance.options
    this.post = this.instance.post
    this.put = this.instance.put
    this.patch = this.instance.patch
    this.available = false
  }

  get isActive() {
    return  Boolean(this.access?.isUp && this.refresh?.isUp)
  }

  get shouldRefresh() {
    return Boolean(this.refresh?.isUp && !this.access?.isUp)
    // return !!this.refresh
    //     && this.refresh.isUp
    //     && (!this.access || !this.access.isUp)
  }

  setAuthHeader() {
    this.headers.Authorization =
        `Bearer ${this.access}`
  }

  setAutoRefresh() {
    const refreshTime = this.access!.timeToExpiration - TOKEN_EXPIRATION_MARGIN
    if (refreshTime > TIMEOUT_MIN) {
      this.nextAccessRefresh = setTimeout(
          () => this.tokenRefresh(),
          refreshTime
      )
    }

  }

  initAutoAuth() {
    this.setAuthHeader()
    this.available = true
    this.setAutoRefresh()
  }

  updateAccess(access: string) {
    return new Promise<void>((resolve) => {
      this.access = new Token(access)
      storage.set('access', access)
      resolve()
    }).then(() => {
      this.initAutoAuth()
    })
  }

  tokenRefresh(attempt = 1){
    this.available = false
    return this.instance.post(
        'login/refresh/',
        {refresh: this.refresh?.toString()}
    ).then(response => {
      return this.updateAccess(response.data.access)
    }).catch(error => {
      if (attempt < RETRY_RESET_MAX && this.refresh?.isUp) {
        return new Promise( resolve => {
          setTimeout(
            () => resolve(this.tokenRefresh(attempt + 1)),
            attempt * RETRY_RESET_INTERVAL_MS
          )
        })
      } else {
        return Promise.reject(error)
      }
    })
  }

  updatePair(access: Token, refresh: Token) {
    this.access = access
    this.refresh = refresh
    this.initAutoAuth()
  }

  initToken() {
    this.available = false
    const access = storage.get('access', undefined)
    const refresh = storage.get('refresh', undefined)
    if (access && refresh) {
      const accessToken = new Token(access), refreshToken = new Token(refresh)
      if (refreshToken.isUp) {
        if (accessToken.isUp) {
          this.updatePair(accessToken, refreshToken)
        } else {
          this.refresh = refreshToken
          return this.tokenRefresh()
              .catch(() => {}) // silence api error
        }
      }
    }
    return Promise.resolve()
  }

  logOut() {
    const headers = this.instance.defaults.headers.common
    if (Object.prototype.hasOwnProperty.call(headers, 'Authorization')) {
      delete headers.Authorization
    }
    clearTimeout(this.nextAccessRefresh)
    storage.clear()
    this.access = null
    this.refresh = null
    this.available = false
  }

  logIn(credentials: Credentials) {
    let loginCredentials, url
    const { ticket } = credentials
    if (ticket) {
      loginCredentials = {ticket}
      url = 'login/token/'
    } else {
      const { password, username } = credentials
      if (password && username) {
        loginCredentials = {password, username}
        url = 'login/local/'
      } else {
        const { newPassword, newPassword2, token, rid } = credentials
        if (newPassword && newPassword2 && token && rid) {
          loginCredentials = {newPassword, newPassword2, token, rid}
          url = 'login/password-reset/signin/'
        }
      }
    }
    if (url && loginCredentials) {
      return this.instance.post(url, loginCredentials)
          .then(response => {
            const { access, refresh } = response.data
            storage.dump({ access, refresh })
            return this.updatePair(new Token(access), new Token(refresh))
          })
    }
    return Promise.reject(new TypeError('credentials provided are not valid'))
  }
}

export const authAxios = new Authority()

export { RETRY_RESET_MAX, RETRY_RESET_INTERVAL_MS }
