export const fr = {
  about: {
    header: {
      intro: `
        Engagée dans la science ouverte, l'Université de Strasbourg s'est investie dans 
        le développement de la plateforme POUNT, écosystème libre, modulaire et interopérable 
        de description et de visualisation des données issues de projets de recherche.
      `,
      allows: 'Elle permet :',
      possible: {
        save: 'De sauvegarder et versionner les données (documents, images, vidéos, modèles 3D) ;',
        structure: `
          De structurer les données en respectant les standards d'une discipline, et d'étendre ces standards avec des
          métadonnées personnalisées ;
        `,
        config: 'De configurer les droits de lecture et de modification pour les différentes communautés ;',
        value: `
          De valoriser les données, de les visualiser de manière fluide et de les enrichir avec des informations
          contextuelles.
        `,
      },
    },
    mail: 'Pour poser une question ou demander de l\'aide, vous pouvez contacter l\'équipe POUNT par mail :',
    objective: {
      headline: 'Objectif',
      content: `
        Afin de s'inscrire dans les principes FAIR (facile à trouver, accessible, interopérable et réutilisable), 
        <b>POUNT</b> est développé pour permettre aux chercheurs de l’Université de Strasbourg 
        de déposer des jeux de données issus de leurs travaux de recherche de manière structurée (saisie de métadonnées)
         et interopérable (export des métadonnées). 
        Il est conçu de façon à ce que le chercheur puisse gérer ses ensembles de données de façon autonome 
        et de les contextualiser. 
        Cette plateforme transdisciplinaire propose également des outils de visualisation 
        telle qu'une visionneuse de modèles 3D.<br>
        Les chercheurs peuvent inviter des personnes extérieures à l’Université de Strasbourg comme contributeurs. 
        Elle permet également de partager avec accès restreint ou public ces jeux de données 
        et les contenus rendus publics sont accessibles par tout le monde. 
        Enfin, le code a été conçu de manière à être diffusable à tout établissement.
      `,
    },
    title: 'À propos',
  },
  account: {
    login: 'Connexion',
    logout: 'Déconnexion',
  },
  appearance: {
    dark: 'Sombre',
    label: 'Apparence',
    light: 'Clair',
    selection: 'Choix du theme',
  },
  btn: {
    add: 'Ajouter',
    all: 'Tous',
    back: 'Retour',
    cancel: 'Annuler',
    close: 'Fermer',
    confirm: 'Confirmer',
    delete: 'Supprimer',
    download: 'Télécharger',
    edit: 'Éditer',
    help: 'Aide',
    insert: 'Insérer',
    modify: 'Modifier',
    open: 'Ouvrir',
    preview: 'Prévisualiser',
    remove: 'Retirer',
    reset: 'Réinitialiser',
    save: 'Enregistrer',
    saveAndClose: 'Enregistrer et fermer',
    saveAndContinue: 'Enregistrer et continuer',
    send: 'Envoyer',
    share: {
      on: 'Rendre public',
      off: 'Rendre privé'
    },
    signUp: 'S\'inscrire\'',
    submit: 'Soumettre',
    update: 'Mettre à jour',
    validate: 'Valider',
    visualize: 'Visualiser',
  },
  confirm: {
    allRead: '@:confirm.areYouSure marquer toutes vos notifications comme "lues" ? ',
    areYouSure: 'Êtes-vous sûr de vouloir',
    delete: '@:confirm.areYouSure supprimer {object} ?',
    dismiss: '@:confirm.areYouSure enlever {object} de la liste des managers de ce projet ?',
    dismissMyself: '@:confirm.areYouSure vous enlever de la liste des managers de ce projet ?',
    promote: '@:confirm.areYouSure ajouter {object} à la liste des managers de ce projet ?',
    quitPount: 'Vous allez quitter Pount. Voulez vous continuer ?',
    quitPountTitle: 'Confirmer votre navigation',
    remove: '@:confirm.areYouSure enlever {object} du projet ?',
    selection: 'votre selection',
    title: 'Merci de confirmer',
  },
  copiedToClipboard: 'Copié vers le presse-papier',
  copyToClipboard: 'Copier vers le presse-papier',
  createdOn: 'Créé le {date}',
  dashboard: {
    creatorForm: "Accéder au formulaire",
    creatorFormMessage: "Pour commencer à créer vos projets et décrire vos données, merci de remplir le formulaire de demande de création de projet.",
    label: 'Tableau de bord',
    project: {
      delete: 'Supprimer les projets selectionnés',
    },
  },
  detail: 'Détail',
  description: 'description',
  edit: 'éditer',
  error: {
    image: 'L\'image n\'a pas pû être chargée.',
    internal: 'Erreur interne',
    response: 'Erreur {status} : ',
    timeout: 'le server n\'a pas répondu dans les temps',
    undefined: 'Erreur inconnue',
  },
  file: {
    add: 'Ajouter un fichier',
    delete: {
      tooltip: 'Supprimer ce fichier',
      error: 'Erreur lors de la suppression du fichier',
      success: 'Le fichier a bien été supprimé',
    },
    designate: 'le fichier {name}',
    dialogTitle: 'Fichiers téléchargeables',
    download: 'Télécharger',
    downloadable: 'Téléchargeable',
    header: 'Fichiers',
    name: 'Nom',
    size: 'Taille',
    type: 'Type',
    upload: {
      button: 'Envoyer',
      error: 'Error lors du transfert du fichier "{filename}"',
      label: 'Fichier à envoyer',
      success: 'Votre fichier "{filename}" a bien été téléversé',
    },
    visualize: 'Visualiser',
    visualized: 'Visualisé',
  },
  form: {
    field: {
      description: '@.capitalize:description',
      email: 'Courriel',
      name: 'Nom',
      title: 'Titre',
      username: '@:form.field.name d\'utilisateur',
    },
    rule: {
      minPassLength: '8 symboles minimum',
      newPassword: 'ne peut pas être identique à l\'ancien',
      passMatch: 'les deux champs doivent être identiques',
      required: 'ce champ est requis',
    },
  },
  general: "Général",
  group: {
    add: {
      hint: 'Sélectionnez un ou plusieurs utilisateurs',
      title: 'Ajouter un contributeur',
    },
    create: 'Créer un groupe',
    default: {
      managers: 'Gestionnaires',
      members: 'Membres',
    },
    designate: 'le groupe "{name}"',
    edit: 'Modifier le groupe',
    empty: 'Ce groupe ne contient aucun utilisateur pour le moment.',
    label: 'groupe | groupes',
    leave: 'quitter',
    manage: {
      members: 'Gérer les membres',
      permissions: 'Modifier les droits',
    },
    members: {
      actual: 'Liste des contributeurs',
      add: 'clicker pour ajouter',
      available: 'Membres du projet disponibles',
      count: 'Aucun membre| un membre | {count} membres',
      edit: 'Gérer les membres',
      invite: 'Inviter',
      inviteUser: '@:group.members.invite un utilisateur',
      mail: {
        error: 'La personne n\'a pas pu recevoir de notification par courriel',
        sent: 'La personne en a été notifiée par courriel',
      },
      missing: 'Le groupe des membres est introuvable, nouvel ajout impossible.',
      project: 'Membres du projet',
      remove: 'clicker pour retirer',
      self: 'Membres du groupe',
    },
    new: 'Nouveau groupe',
    none: 'Aucun groupe.',
    project: 'Groupes du projet',
    right: {
      actual: 'Ensembles disponibles aux contributeurs',
      add: 'Ajouter en ensemble',
      update: 'Mettre à jour les droits de contribution',
    },
    sets: 'Ensembles de travail',
    tab: 'Groupes',
    update: 'Mettre à jour le groupe "{name}"',
  },
  hop: {
    camera: {
      orthographic: 'Vers la vue orthographique',
      perspective: 'Vers la vue en perspective',
    },
    close: 'Fermer les outils',
    fullscreen: {
      exit: 'Sortir du plein écran',
      request: 'Passer en plein écran',
    },
    help: 'Masquer l\'aide',
    light: {
      off: 'Couper la source lumineuse',
      on: 'Allumer la source lumineuse',
      source: {
        lock: 'Fixer la source lumineuse',
        move: 'Déplacer la source lumineuse',
      },
    },
    measure: {
      label: 'Longueur mesurée',
      help: 'Outil de mesure',
    },
    point: {
      label: 'Coordonnées [x,y,z]',
      help: 'Prise de coordonnées',
    },
    reset: 'Réinitialiser la position',
    sections: {
      flip: {
        label: 'Inverse',
        title: 'Inverser la direction pour la section selon {axis}',
      },
      help: 'Plans de coupe',
      plane: {
        label: 'Plan',
        title: 'plan de section orthogonal à l\'axe {axis}',
        on: 'Ajouter un @:hop.sections.plane.title',
        off: 'Enlever le @:hop.sections.plane.title',
      },
      position: {
        label: 'Position',
        title: 'Déplacer la section selon l\'axe {axis}',
      },
      showPlanes: {
        label: 'Plans visibles',
        title: 'Afficher les plans de coupe',
      },
      showEdges: {
        label: 'Bords visibles',
        title: 'Mettre en évidence la section de coupe',
      },
    },
    screenshot: 'Faire une capture d\'écran',
    texture: {
      disable: 'Retirer les textures',
      restore: 'Restorer les textures',
    },
    zoom: {
      plus: 'Zoomer',
      minus: 'Dézoomer',
    },
  },
  invite: {
    title: 'Inviter un utilisateur',
    success: '{username} a bien été ajouté aux membres de ce projet',
    failure: '{username} n\'a pas pu être ajouté aux membres de ce projet',
  },
  item: {
    backToSet: 'Revenir à l\'ensemble',
    copy: {
      inTarget: 'dans cet ensemble : "{values}"',
      toSet: 'Vers le set "{title}"',
      move: 'Déplacer',
      duplicate: 'Copier',
      title: 'Copier @:item.designate',
      here: 'Copier ici',
      ignored: {
        field: 'Ce champ sera ignoré. | Ces champs seront ignorés.',
        value: `Cette donnée de l'élément sera ignorée, le champ est manquant dans cet ensemble.
         | Ces données de l'élément seront ignorées, les champs sont manquant dans cet ensemble.`,
      },
      impossible: 'La copie est impossible, ',
      required: `@:item.copy.impossible ce champ est requis. 
        | @:item.copy.impossible ces champs sont requis.`,
      partial: 'Attention : Seule une copie partielle est possible.',
      inProject: 'Choisir l\'ensemble',
      projectsLoad: 'Charger les autres projets',
      prefix: 'Copie de ',
      reason: {
        unknownOption: `Cette option est inconnue @:item.copy.inTarget
         | Ces options sont inconnues @:item.copy.inTarget`,
        typeMismatch: 'Le type du champ cible ne correspond pas avec la donnée de l\'élément',
        tooManyValues: 'Ce champ reçoit trop de données, maximum : {limit}',
        missingValue: 'Donnée(s) manquante(s)',
      },
    },
    count: 'Aucun élément | {count} élément | {count} éléments',
    create: 'Créer un nouvel élément',
    submitted: 'Déposé par : ',
    description: 'Présentation',
    designate: 'cet élément',
    destroy: 'Supprimer l\'élément',
    destroy_text: 'Cela supprimera l\'élément définitivement. Étes-vous sûr(e) ?',
    detail: '@:detail de l\'@.lower:item.label ',
    edit: 'Editer l\'élément',
    export: 'Export Datacite',
    import: {
      button: 'Import CSV',
      success: 'Import du fichier "{filename}" réussi !',
      created: '@:item.count ajoutés à l\'ensemble.',
      error: 'Les objets n\'ont pas pu être créés à partir du fichier "{filename}".',
      launch: 'Lancer l\'import',
    },
    file: 'Fichier',
    label: 'Élément',
    none: 'Aucun élément.',
    metadata: 'Métadonnées',
    plural: 'Éléments',
    shareLink: 'Visualiser l\'@.lower:item.label dans Pount',
    update: 'Modifier l\'élément',
    updated: 'L\'élément a bien été mis à jour',
    fields: {
      title: {
        label: 'Titre',
        empty: 'Élément sans titre',
      },
      description: {
        label: '@.capitalize:description',
        empty: 'Aucune @:description',
      },
      identifier: {
        label: 'Identifiant unique',
        empty: 'Aucun DOI associé',
      },
      creator: {
        label: 'Auteur',
        empty: 'Auteur inconnu',
      },
      contributor: {
        label: 'Contributeurs',
        empty: 'Aucun contributeur renseigné',
      },
      publisher: {
        label: 'Éditeur',
        empty: 'Éditeur inconnu',
      },
      publicationYear: {
        label: 'Année de publication',
        empty: 'Non renseignée',
      },
      resourceTypeGeneral: {
        label: 'Type de ressource général',
      },
      resourceType: {
        label: 'Type de ressource',
      },
      language: {
        label: 'Langue',
        empty: 'Non renseignée',
      },
      size: {
        label: 'Taille',
        empty: 'Inconnue',
      },
      format: {
        label: 'Type',
        empty: 'Inconnu',
      },
      version: {
        label: 'Version',
        empty: 'Non versionné',
      },
    },
    tabs: {
      copy: 'Copie',
      detail: '@:detail',
      files: 'Fichiers',
      metadata: '@:item.metadata',
      viewer: 'Visionneuse',
    },
    showEmptyMetadata: 'Afficher les métadonnées non renseignées',
    hideEmptyMetadata: 'Masquer les métadonnées non renseignées',
    delete: 'Supprimer cet élément',
    share: 'Partager cet ensemble',
    exportDatacite: 'Exporter les métadonnées datacite vers un fichier XML'
  },
  language: {
    label: 'Langue',
    selection: 'Choix de la langue',
  },
  lastUpdate: 'Date de dernière mise à jour : { lastUpdate }',
  legal: {
    headline: 'Mentions légales',
    editor: {
      name: 'Université de Strasbourg',
      headline: 'Editeur',
      property: 'Le présent site est la propriété de l\'Université de Strasbourg (4 Rue Blaise Pascal, 67081 Strasbourg).',
      complain: 'Pour toute réclamation vous pouvez envoyer un mail à',
    },
    director: {
      headline: 'Directeur de la publication',
      content: 'Le directeur de la publication est Monsieur Michel Deneken, Président de l’Université de Strasbourg.',
    },
    credits: {
      headline: 'Crédits',
      libraryService: 'Service des Bibliothèques Universitaires',
    },
    host: 'Hébergeur',
    ownership: {
      headline: 'POUNT et la Propriété intellectuelle',
      definition: `
          L'intégralité du site se définit comme une base de données au sens de la directive du 11 mars 1996 et de sa
          transposition en droit français dans la loi du 1er juillet 1998 (article L 341-1 et suivants du code de
          propriété intellectuelle).
        `,
    },
    researchData: {
      headline: 'Les données de recherche',
      content: `
          Les données de recherche collectées sont des jeux de données issus de projet de recherche. Le propriétaire des
          données gère les métadonnées qui contextualise le jeu de données et décide de sa confidentialité. En fonction,
          des droits affectés par le chercheur ces données seront accessibles publiquement ou en mode restreint à des
          groupes d’utilisateurs définis par le chercheur. La durée de conservation des données est définie par le
          chercheur mais est indéfinie pour les jeux de données issus de projets de recherche financés par l’Université de
          Strasbourg. 
        `,
    },
    reuseContent: {
      headline: 'Réutilisation des contenus',
      content: `
          En l'absence de la mention d'une licence d'usage et de réutilisation des données sur la page d'un projet, 
          d'un ensemble ou sur la page d'un item, l'internaute doit respecter les droits de propriété intellectuelle
          sur les contenus mis en ligne. Ainsi, sauf exceptions du Code de la Propriété Intellectuelle 
          (et notamment exception de courte citation), il ne peut reproduire ou représenter, totalement ou partiellement, 
          le contenu mis en ligne sans le consentement préalable et écrit de l'auteur. Se référer à chaque page projet
          pour connaître les modalités de réutilisation.<br/>
          En toute hypothèse et compte tenu de ce qui précède, l'utilisateur du site et des informations qu'il contient
          reconnaît qu'il en fait usage sous sa seule responsabilité. L'Université de Strasbourg ne saurait en conséquence
          voir sa responsabilité engagée à raison de tout préjudice, direct ou indirect, de quelque nature que ce soit,
          résultant pour tout ou partie de l'utilisation des informations du site.
        `,
    },
    dgpr: {
      headline: 'Protection des données personnelles',
      collect: `
          Il n’y a pas de collecte de vos données si vous ne faites que consulter le site.
          Les personnes extérieures à l’Université de Strasbourg amenées à contribuer à une collection devront
          renseigner un formulaire au préalable.<br>
          Concernant les chercheurs à l’Université de Strasbourg les données pour la création du compte seront
          collectées indirectement lors de la première connexion sur la plateforme à l’aide des identifiants E.N.T.
          Les données collectées concernant les utilisateurs qui se connectent sont :
          <i>Nom usuel, prénom, adresse de messagerie, structure de rattachement</i>.<br>
          Ces données permettent la traçabilité des déposants ainsi que de pouvoir citer le jeu de données ou d’un
          élément. Ces données sont conservées tant que l’utilisateur ne fait pas de demande de suppression de compte
          auprès du responsable d’application.
        `,
      access: `
          Les données des utilisateurs disposant d’un accès à la plateforme sont traitées par la Direction du numérique
          de l’Université de Strasbourg. Si les contributeurs déposent des jeux de données leurs identités et
          éventuellement leurs coordonnées seront conservées au niveau des métadonnées des données déposées. La base
          légale du traitement est l’exécution d’une mission d’intérêt publique article 6 (1) e. du RGPD. Le traitement
          n’entraîne pas de décision automatisée à l’égard des utilisateurs, et aucun transfert de données hors de l’Union
          européenne n’est effectué. Conformément au règlement (UE) 2016/679 du 27 avril 2016 du Parlement européen et du
          Conseil et la loi n°78-17 modifiée, les utilisateurs titulaires d’un compte disposent de droits d'accès,
          rectification et suppression de leurs données. Ils peuvent également demander la limitation du traitement.
          Pour les exercer la demande peut être adressée à : 
        `,
      dpo: `
          L'Université de Strasbourg a désigné une déléguée à la protection des données joignable à l'adresse suivante :
          <a href='mailto:dpo{'@'}unistra.fr' title="Écrire à la déléguée à la protection des données">dpo{'@'}unistra.fr</a>.
        `,
      cnil: `
          Si vous estimez, après nous avoir contactés, que vos droits sur vos données ne sont pas respectés, vous pouvez
          <a href='https://www.cnil.fr/fr/webform/adresser-une-plainte'
             title="Formulaire de dépôt de plainte sur le site de la CNIL"
          >
           adresser une réclamation (plainte) à la CNIL
          </a>.
        `,
    },
    cookies: {
      headline: 'Cookies',
      description: 'Le seul cookie déposé servira à l’authentification des utilisateurs disposant d’un compte.',
    },
    floss: {
      headline: 'POUNT, un projet libre',
      description: `Le code source de POUNT est régi par les termes de la licence
        <a href='https://www.gnu.org/licenses/gpl-3.0.fr.html' title="GPL3 license terms">
          GNU General Public License, version 3
        </a>.
        Cela signifie que le code source de POUNT peut être modifié, redistribué et utilisé dans un but privé ou commercial.
        Chaque modification doit cependant être documentée, et le code source et le texte de la même licence doivent être inclus avec toute modification ou redistribution.
        `,
      repositories: `<ul>
        <li>Le code source de l'API POUNT est disponible <a href='https://git.unistra.fr/community/pount-api' title="POUNT API repository">à cet adresse</a>.</li>
        <li>Le code source du client POUNT est disponible <a href='https://git.unistra.fr/community/pount-front' title="POUNT API repository">à cet adresse</a>.</li>
        </ul>`,
    },
  },
  logout: 'A bientôt',
  mail: {
    failure: 'L\'email n\'a pas pu être envoyé à l\'adresse "{email}"',
    success: 'Un email a bien été envoyé à l\'adresse "{email}"',
    title: 'Écrire au projet POUNT',
  },
  menu: {
    home: 'Accueil',
    about: 'À propos',
    legal: 'Mentions légales',
    help: 'Aide',
    settings: 'Paramètres',
    dark: 'Mode Nuit : {mode}',
    feedback: 'Contact',
    language: 'Langue',
    theme: 'Thème',
    manage: 'Gérer votre profil',
    userProfile: 'Mon profil',
    logout: 'Me déconnecter',
  },
  notFound: {
    title: 'Page non trouvée 🙀',
    content: `
      Vous êtes peut-être ici car :
      <ul>
        <li>Cette page que vous pensiez exister ... n'existe pas</li>
        <li>Cette page existe, mais une mauvaise adresse a été entrée</li>
        <li>Vous cherchiez votre chaton mais vous vous êtes perdus</li>
        <li>Vous adorez les pages 404</li>
      </ul>
    `,
    backDashboard: 'Retourner au tableau de bord',
    backHome: 'Retourner à l\'accueil',
  },
  notification: {
    error: {
      couldNotFetchNotifications: 'Les dernières notifications n\'ont pas pu être récupérées',
      markRead: 'La notification n\'a pas pu être marquée comme lue',
      markAllRead: 'Les notifications n\'ont pas pu être marquées comme lues',
    },
    menu: {
      noUnreadNotifications: 'Félicitations, vous n\'avez plus de notification non lue',
      markAsRead: 'Marquer comme lue',
      allRead: 'Toutes lues',
      close: 'Fermer',
    },
    pagination: {
      previousPageLabel: 'Page précédente',
      nextPageLabel: 'Page suivante',
    },
  },
  notify: {
    delete: {
      request: {
        error: 'Votre demande de suppression n\'a pas pu aboutir',
        success: 'Votre demande de suppression à bien été envoyée',
      },
      success: 'La suppression à bien été effectuée',
    },
    error: {
      login: 'Les informations transmises ne nous ont pas permis de vous connecter',
      update: 'La mise à jour à échoué',
      createUser: 'Erreur lors de la création de compte',
      groups: {
        create: 'Nous n\'avons pas pu créer ce groupe',
        all: 'Nous n\'avons pas pu charger la liste des groupes',
        delete: 'Nous n\'avons pas pu supprimer le groupe',
        user: {
          add: 'Nous n\'avons pas pu ajouter ce contributeur',
          remove: 'Nous n\'avons pas pu retirer ce contributeur',
        },
        set: {
          add: 'Nous n\'avons pas pu ajouter cet ensemble',
          remove: 'Nous n\'avons pas pu retirer cet ensemble',
        },
      },
      invitation: {
        validationFailed: 'Votre invitation n\'a pas pu être validée',
        dataMissing: 'Il manque des données pour valider votre invitation',
      },
      item: {
        all: 'Nous n\'avons pas pu charger la liste des éléments',
        load: 'Nous n\'avons pas pu charger l\'élément demandé',
        create: 'Nous n\'avons pas pu créer votre élément',
        delete: 'L\'élément n\'a pas pu être supprimé | Les éléments n\'ont pas pu être supprimés',
      },
      project: {
        all: 'Nous n\'avons pas pu charger la liste des projets',
        load: 'Nous n\'avons pas pu charger le projet demandé',
        create: 'Nous n\'avons pas pu créer votre projet',
        // update: "Le projet n'a pas pu être mis à jour",
      },
      searchSettings: 'La mise à jour de la configuration de la recherche a échoué.',
      selfProfile: 'le chargement de votre profil a échoué',
      set: {
        all: 'Nous n\'avons pas pu charger la liste des ensembles',
        load: 'Nous n\'avons pas pu charger l\'ensemble demandé',
        create: 'Nous n\'avons pas pu créer votre ensemble',
        delete: 'L\'ensemble n\'a pas pu être supprimé | Les ensembles n\'ont pas pu être supprimés',
        // update: "L'ensemble' n'a pas pu être mis à jour",
      },
      template: {
        all: 'Nous n\'avons pas pu charger la liste des modèles',
        load: 'Nous n\'avons pas pu charger le modèle demandé',
        create: 'Nous n\'avons pas pu créer votre modèle',
        // update: "L'ensemble' n'a pas pu être mis à jour",
        delete: 'Un template n\' a pas pu être supprimé. | Des templates n\'ont pas pu être supprimés.',
        deleteAll: 'Aucun template n\'a pu être supprimé.'
      },
      referential: {
        all: 'Nous n\'avons pas pu charger la liste des référentiels.',
        create: 'Nous n\'avons pas pu créer votre @:referential.base',
        update: 'Nous n\'avons pas pu mettre à jour votre @:referential.base',
      }
    },
    success: {
      template: {
        delete: 'Le template a été supprimé. | Les templates ont été supprimés',
      },
      group: {
        create: 'Le groupe a été créé.',
        delete: 'Le groupe a été supprimé',
      },
    },
    updated: {
      succeed: 'a bien été mis à jour',
      group: 'Le groupe @:notify.updated.succeed',
      searchSettings: 'La configuration de la recherche a bien été mise à jour',
      template: 'Le modèle @:notify.updated.succeed',
    },
  },
  off: 'OFF',
  on: 'ON',
  pagination: {
    perPage: '{item} par page',
  },
  password: {
    label: 'Mot de passe',
    old: 'Ancien mot de passe',
    new: 'Nouveau mot de passe',
    confirm: 'Confirmer votre mot de passe',
    change: 'Changer de mot passe',
    failure: 'Nous n\'avons pas pu mettre à jour votre mot de passe',
    success: 'Votre mot de passe a bien été mis à jour',
    reset: {
      title: 'Réinitialiser mon mot de passe',
      explanation: `
        Si l'adresse ci-dessous correspond bien à un compte POUNT,\n
        un courriel vous sera envoyé.
      `,

    },
    forgot: 'Mot de passe oublié ?',
  },
  private: 'Privée',
  profile: {
    personalInfo: 'Informations personnelles',
    contact: 'Contact',
    update: {
      success: 'Votre profil à bien été mis à jour',
      failure: 'Votre profil n\'a pas pu être mis à jour',
      wanted: {
        button: 'Mettre à jour',
        deco: 'Vous allez être déconnecté',
        failed: 'Votre demande n\'a pas pu aboutir',
        next: 'Vos informations seront mises à jour à la prochaine connexion, cette opération nécessite de se reconnecter.',
      },
    },
    changeMail: {
      title: 'Changement d\'email',
      new: 'Nouvelle adresse',
      previous: 'Changez votre adresse : "{previousMail}"',
    },
    firstName: 'Prénom',
    lastName: 'Nom',
    bio: 'Bio',
    email: 'Courriel',
    researchLab: {
      acronym: 'Acronyme',
      field: 'Unités de recherches',
      label: 'Label',
      longName: 'Libellé long',
      shortName: 'Libellé court',
    },
    institution: 'Établissement',
    affiliations: 'Affiliations',
    otherAffiliations: 'Autres affiliations',
    newAffiliation: 'Ajouter une affiliation',
  },
  project: {
    name: 'Nom du projet',
    description: '@.capitalize:description',
    designate: 'ce project',
    detail: '@:detail du @.lower:project.label',
    generalInfo: {
      label: 'Informations générales',
      updated: 'Les informations générales du projet ont bien été mises à jour.',
    },
    edit: '@.capitalize:edit le @.lower:project.label',
    editReferential: 'Editer le @.lower:referential.label',
    isPublic: 'Rendre le projet public',
    create: 'Créer un projet',
    self: 'Mon projet | Mes projets',
    contributions: 'Ma contribution | Mes contributions',
    label: 'Projet',
    plural: 'Projets',
    link: 'Voir',
    welcome: 'Bienvenue sur "{name}"',
    manager: 'Gestionnaire',
    managers: 'Gestionnaire | Gestionnaires',
    promoteManager: 'Promouvoir @.lower:project.manager',
    dismissManager: 'Destituer @.lower:project.manager',
    removeMember: 'Retirer du projet',
    removeReferential: 'Supprimer le @.lower:referential.label',
    subtitles: {
      managers: '{label} : {names}',
    },
    contributor: 'Contributeur',
    search: '@:search.title dans le @.lower:project.label',
    searchInfo: 'Ces critères de recherche ne s\'appliquent qu\'aux éléments',
    searchExport: 'Exporter les résultats de la recherche en CSV',
    searchReverseSortOrder: 'Inverser l\'ordre de tri',
    moreCriteria: 'Plus de critères',
    viewReferential: 'Visualiser le @.lower:referential.label',
    delete: 'Supprimer ce projet',
    deleteSet: 'Supprimer les ensembles selectionnés',
    deleteTemplate: 'Supprimer les modèles selectionnés',
  },
  public: 'Publique',
  referential: {
    base: 'référentiel | référentiels',
    bind: 'Utiliser dans un projet',
    create: 'Créer un référentiel',
    createStandard: 'Créer un référentiel standard',
    created: '@:referential.label sauvegardé',
    deleteSuccess: 'Le référentiel a été supprimé.',
    deleteError: 'Une erreur est survenue pendant la suppression du référentiel',
    description: '@.capitalize:description',
    field: 'Options de @.lower:referential.label',
    fromProject: 'Projet d\'origine: ',
    label: 'Référentiel | Référentiels',
    lang: {
      add: 'Ajouter une langue',
      available: 'Langues disponibles',
      default: 'Langue par défaut',
      required: 'Une langue est requise',
    },
    level: {
      add: 'Ajouter un niveau de noeud',
      display: 'Definition des niveaux de noeud',
    },
    list: {
      sort: {
        name: {
          ascending: 'Nom [A-Z]',
          descending: ' Nom [Z-A]'
        },
        scope: {
          ascending: 'Statut [base-public]',
          descending: 'Statut [public-base]',
          project: {
            ascending: 'Statut [base-public-projet]',
            descending: 'Statut [projet-public-base]'
          }
        }
      },
      use: {
        confirmTitle: 'Utiliser dans le projet',
        confirmMessage: 'Selectionnez le projet dans lequel vous souhaitez utiliser le référentiel.',
        error: 'La liaison du référentiel au projet a échoué.',
        success: 'Le référentiel a été lié au projet.'
      }
    },
    name: 'Nom du @.lower:referential.label',
    node: {
      add: 'Ajouter un(e) {level} au nœud {parent}',
      insert: 'Insertion de {levelName}'
    },
    rename: 'Renommer "{name}"',
    select: 'Options de niveau',
    translations: 'Traductions',
    translationsOf: 'Traductions de {name}',
    tree: {
      newRoot: 'Ajouter un(e) {rootLevelName}',
      exist: 'Une branche porte déjà ce nom !',
    },
    unbind: 'Délier le référentiel du projet',
    unbindSuccess: 'Le référentiel a été délié du projet.',
    unbindError: 'Une erreur est survenue pendant le déliage du référentiel.',
    updated: '@:referential.label mis à jour',
  },
  search: {
    aggregation: {
      bucket: {
        boolean: {
          true: 'Oui',
          false: 'Non',
        },
        item: '@:item.label | @:item.plural',
        set: '@:set.label | @:set.plural',
        project: '@:project.label | @:project.plural',
      },
      labels: {
        isPublic: 'Objets publiques',
        docType: 'Type d\'objet',
      },
    },
    label: 'Recherche',
    noResult: 'Aucun résultat trouvé pour {query}',
    placeholder: 'Rechercher dans les données publiques',
    title: 'Rechercher',
    results: 'Résultats de la recherche',
    sortOptions: 'Options de tri',
    relevance: 'Pertinence',
  },
  set: {
    title: 'Titre',
    delete: 'Supprimer cet ensemble',
    description: '@.capitalize:description',
    designate: 'cet ensemble',
    detail: '@:detail de l\'@.lower:set.label ',
    edit: '@.capitalize:edit l\'@.lower:set.label',
    generalInfo: {
      updated: 'Les informations générales de l\'ensemble ont bien été mises à jour',
    },
    publisher: 'Éditeur',
    initiator: 'Initié par : {name}',
    template: 'Modèle de métadonnées',
    count: 'Aucun ensemble | {count} ensemble | {count} ensembles',
    label: 'Ensemble',
    plural: 'Ensembles',
    create: 'Créer un ensemble',
    created: 'Nouvel ensemble créé',
    update: 'Modifier l\'ensemble',
    destroy: 'Supprimer l\'ensemble',
    destroy_text: 'Cela supprimera l\'ensemble définitivement. Étes-vous sûr(e) ?',
    share: 'Partager cet ensemble',
    shareAllItems: 'Partager tous les éléments.',
    shareCarousel: 'Mode carousel',
    shareLink: 'Visualiser l\'@.lower:set.label sur Pount',
    shareModalIntegrationCode: 'Code d\'intégration',
    shareModalOptions: 'Options',
    shareModalText: 'Veuillez séléctionner les éléments de l\'ensemble à partager.',
    shareOptions: 'Options d\'intégration',
    empty: 'Cet ensemble ne contient aucun élément pour l\'instant.',
    none: 'Aucun ensemble.',
    cant_read: 'Vous n\'avez pas la permission de voir le contenu de cet ensemble.',
    search: {
      addToAggregation: 'Ajouter à l\'aggregation',
      addToSearchFields: 'Ajouter aux champs de recherche',
      label: '@:search.title dans l\'@.lower:set.label',
      metadataType: 'Type de donnée',
      settings: 'Configuration de la recherche',
      types: {
        bools: 'Booléen',
        dates: 'Date',
        floats: 'Nombre à virgule flottante',
        keyword: 'Texte binaire',
        longs: 'Nombre entier',
        strings: 'Texte',
        stringsFr: 'Texte français',
      },
    },
    self: 'Nouvel ensemble | Mon ensemble | Mes ensembles',
    validation: {
      title: 'Cet ensemble ne peut être créé pour les raisons suivantes :',
      field_missing: 'La métadonnée \'{field}\' doit exister.',
      field_required: 'La métadonnée \'{field}\' est obligatoire.',
    },
    itemsImport: 'Importer des élèments à partir d\'un fichier CSV',
    deleteItem: 'Supprimer les éléments sélectionnés'
  },
  signIn: {
    title: 'Me connecter',
    description: 'Connectez-vous pour visualiser vos propres données sur Pount.',
    creation: 'Lors de votre première connexion, un compte utilisateur sera créé.',
    cookies: 'Pour vous connecter avec Google ou GitHub, les cookies tiers doivent être autorisés dans les préférences de votre navigateur.',
    notMember: 'Pas encore membre de la communauté ?',
    join: 'Rejoindre',
    continue: 'Continuer avec {method}',
  },
  signature: {
    short: 'POUNT',
    long: 'Plateforme OUverte Numérique Transdisciplinaire',
  },
  template: {
    csv: {
      export: 'Exporter en CSV',
      warning: 'Attention',
      noOption: `Le fichier exporté ne contient pas les contraintes de saisie telles que des listes déroulantes, cases à cocher....
       <br>Soyez y attentif lors de la construction de votre fichier d'import.`,
    },
    label: 'Modèle',
    plural: 'Modèles',
    scopes: {
      base: 'Standard',
      global: 'Partagé',
      project: 'Projet',
      public: 'Public',
      set: 'Ensemble',
    },
    edit: 'Editer ce template',
    view: 'Visualiser ce template',
    tab: {
      project: 'Modèles de métadonnées',
      set: 'Modèle',
    },
    create: 'Créer un modèle',
    new: 'Nouveau modèle',
    form: {
      baseTemplate: {
        label: 'Modèle de départ',
        placeholder: 'Veuillez sélectionner le modèle qui servira de base',
      },
    },
  },
  themes: {
    dark: 'Sombre',
    light: 'Clair',
  },
  updated: {
    ago: 'il y a',
    self: 'Mis à jour',
    label: '@:updated.self @:updated.ago {time}',
    second: 'seconde | secondes',
    minute: 'minute | minutes',
    hour: 'heure | heures',
    day: 'jour | jours',
    month: 'mois | mois',
    year: 'an | ans',
  },
  user: {
    self: 'Utilisateur',
    internal: 'Interne',
    external: 'Externe',
    internalUser: '@:user.self interne',
    externalUser: '@:user.self externe',
    designate: 'l\'Utilisateur "{name}"',
    welcome: 'l\'équipe de POUNT vous souhaite la bienvenue !',
  },
  visibility: {
    public: 'Visible par tous',
    restricted: 'Visible uniquement des contributeurs',
  },
};
