import {authAxios} from '@/services/authService'

export const getUnreadNotifications = async (page: number) => {
  const response = await authAxios.get('notifications/unread/', { params: { page } })

  return response.data
}

export const patchMarkReadNotification = async (notificationId: number) => {
  const response = await authAxios.patch(`notifications/unread/${notificationId}/mark_read/`)

  return response.data
}

export const patchMarkReadAllNotifications = async () => {
  return await authAxios.patch('notifications/mark_all_read/')
}
