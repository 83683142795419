import { computed } from 'vue'
import { useQuasar } from 'quasar'
import { useI18n } from 'vue-i18n'

import type { ThemeOption, Theme } from '#/user'
// TODO: Store theme in user preferences
// import authority from '@/services/AuthService'
// import { useUserStore } from '@/stores/useUserStore'

type DisplayModes = Record<Theme, ThemeOption>

export function useAppearance() {
  const quasar = useQuasar()
  const { t } = useI18n()
  // TODO: Store theme in user preferences
  // const userStore = useUserStore()

  const modes: DisplayModes = {
    light: { id: 'light', label: t('appearance.light'), value: 'light', isActive: !quasar.dark.isActive },
    dark: { id: 'dark', label: t('appearance.dark'), value: 'dark', isActive: quasar.dark.isActive },
  }

  const modesArray = computed(() => Object.values(modes))

  const setMode = async (modeId: Theme/*, mustUpdate = true*/) => {
    quasar.dark.set(modeId === 'dark')
    localStorage.setItem('pountMode', modeId)
    // TODO: Store theme in user preferences
    // if (mustUpdate && authority.isActive) await userStore.updateUserPreferences({ appearance: modeId })
  }

  const setDefaultQuasarMode = async () => {

    const localTheme = localStorage.getItem('pountMode')
    const darkTheme = localTheme === 'dark'
        || (!localTheme && window.matchMedia('(prefers-color-scheme: dark)').matches)

    quasar.dark.set(darkTheme)
    // TODO: Store theme in user preferences
    // if (authority.isActive) {
    //   if (!userStore.preferences.isLoaded) {
    //     await userStore.updateUserPreferences({ appearance: darkTheme ? 'dark' : 'light' })
    //   }
    //   quasar.dark.set(userStore.isDarkUserPreference)
    // } else {
    //   quasar.dark.set(darkTheme)
    // }
  }

  return {
    modes,
    modesArray,
    setMode,
    setDefaultQuasarMode,
  }
}
