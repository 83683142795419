import { authAxios } from '@/services/authService'

import type { PaginationObject } from '#/crud';
import type { NewReferential, NodeBase, Referential, ReferentialFilters } from '#/referential'

export async function getProjectReferentials(pagination: PaginationObject, filters: ReferentialFilters, projectId: string) {
  const { page, rowsPerPage, sortBy, descending } = pagination

  const size = `&size=${ rowsPerPage > 0 ? rowsPerPage : 0}`
  const ordering = sortBy ? `&ordering=${descending ? '-' : ''}${sortBy}` : ''
  const project = filters.project ? `&project=${filters.project}` : ''
  const search = filters.search ? `&search=${filters.search}` : ''
  const response = await authAxios.get(`projects/${projectId}/referentials/?page=${page}${size}${ordering}${project}${search}`)

  return response.data
}

export async function getPublicReferentials(pagination: PaginationObject, filters: ReferentialFilters) {
  const { page, rowsPerPage, sortBy, descending } = pagination

  const size = `&page_size=${ rowsPerPage > 0 ? rowsPerPage : 0}`
  const ordering = sortBy ? `&ordering=${descending ? '-' : ''}${sortBy}` : ''
  const project = filters.project ? `&project=${filters.project}` : ''
  const search = filters.search ? `&search=${filters.search}` : ''
  const response = await authAxios.get(`referentials/?page=${page}${size}${ordering}${project}${search}`)

  return response.data
}

export async function getReferential(referentialId: string): Promise<Referential> {
  const response = await authAxios.get(`referentials/${referentialId}/`)
  return response.data as Referential
}
export async function postReferential(newReferential: NewReferential): Promise<Referential> {
  const response = await authAxios.post(`referentials/`, newReferential)
  return response.data as Referential
}

export async function patchReferential(referential: Partial<Referential>): Promise<Referential> {
  const response = await authAxios.patch(`referentials/${referential.id}/`, referential)
  return response.data as Referential
}

export async function patchScope(referentialId: string, scopeData: {scope: 1 | 2 | 4}): Promise<number> {
  const response = await authAxios.patch(`referentials/${referentialId}/share/`, scopeData)
  return response.data.scope
}

export async function deleteReferential(referentialId: string) {
  await authAxios.delete(`referentials/${referentialId}`)
}

export async function getLevelNodes(referentialId: string, levelId: string, locale: string, ancestor?: string): Promise<NodeBase[]> {
  let url = `referentials/${referentialId}/nodes/?level_id=${levelId}&lang=${locale}`
  if (ancestor) {
    url += `&ancestor=${ancestor}`
  }
  const response = await authAxios.get(url)
  return response.data
}
