<script setup lang="ts">
import type { InternalLink } from '#/layout';
import { computed } from 'vue'
import { useQuasar } from "quasar"
import { useI18n } from "vue-i18n"

const quasar = useQuasar()
const { t } = useI18n()

const isDark = computed(() => quasar.dark.isActive)
const editor = computed(() => t('legal.editor.name'))
const currentYear = computed(() => new Date().getFullYear())
const links = computed<InternalLink[]>(() => [
  { name: 'legal', label: t('menu.legal'), to: { name: 'legal' } },
  { name: 'contact', label: t('menu.feedback'), to: { name: 'contact' } },
  { name: 'about', label: t('menu.about'), to: { name: 'about' } },
])
</script>

<template>
  <q-footer
    class="text-center"
    :class="{
      'bg-elevation-8': isDark,
      'bg-grey-2': !isDark,
    }"
    :bordered="!isDark"

  >
    <section>
      <p>{{ currentYear }} – {{ editor }}</p>
      <p>
        <template
          v-for="(link, index) in links"
          :key="`link-footer-${link.name}`"
        >
          <router-link :to="link.to"> {{ link.label }}</router-link>
          <span v-if="index < links.length - 1"> - </span>
        </template>
      </p>
    </section>
  </q-footer>
</template>

<style lang="scss" scoped>
.q-footer {
  min-height: 50px;

  p {
    margin: 0;
    color: var(--uni-text-color);

    &:first-of-type {
      margin-top: 11px;
    }

    &:last-of-type {
      margin-bottom: 11px;
    }
  }
}
</style>
