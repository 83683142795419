import { publicAxios, authAxios } from '@/services/authService'
import type { SearchResponse } from '#/search'

export const getAuthSearchResults = async (query: string): Promise<SearchResponse> => {
  const response = await authAxios.get(`search/?${query}`)
  return response.data
}

export const getPublicSearchResults = async (query: string): Promise<SearchResponse> => {
  const response = await publicAxios.get(`search/?${query}`)
  return response.data
}
