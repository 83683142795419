<script setup lang="ts">
import { useSearchStore } from '@/stores/search';
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router';

type Props = {
  mobile?: boolean
}

withDefaults(defineProps<Props>(), {
  mobile: false,
})

const { t } = useI18n({ useScope: 'global' })
const router = useRouter()

// Data
const searchStore = useSearchStore()
const { toSearch } = storeToRefs(searchStore)
const _toSearch = ref('')
watch(toSearch, () => {
  if (router.currentRoute.value.name === 'search' && _toSearch.value !== toSearch.value) _toSearch.value = toSearch.value
})

const onSearch = () => {
  if (!_toSearch.value) return

  searchStore.$reset()
  router
    .push({
      name: 'search',
      query: { 
        q: _toSearch.value,
      },
    })
}
</script>

<template>
  <div 
    class="search-bar row no-wrap"
    :class="{
      'search-bar__mobile': mobile,
    }"
  >
    <q-form
      class="col"
      @submit="onSearch"
    >
      <q-input
        v-model="_toSearch"
        color="primary"
        :label="t('search.label')"
        :placeholder="t('search.placeholder')"
        dense
      >
        <template #prepend>
          <q-icon
            color="primary"
            name="mdi-magnify"
          />
        </template>
      </q-input>
    </q-form>
  </div>
</template>

<style scoped lang="scss">
.search-bar {
  min-width: 100px;
  width: 25%;
  &__mobile {
    width: 100%;
  }
}
</style>
