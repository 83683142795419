<script lang="ts" setup>
import { NOTIFICATION_INTERVAL, useDialogStore } from '@/stores/dialog'
import Notification from "@/components/menu/notifications/SingleItem.vue"
import ConfirmBox from '@/components/common/ConfirmBox.vue';
import { computed, onBeforeUnmount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const {t} = useI18n()

const openReadAllConfirm = ref(false)
const panelIsOpen = ref(false)

const store = useDialogStore()
const notify = computed(() => store.notify)
const unreadCount = computed(() => store.unreadCount)
const cantGetPrevious = computed(() => store.cantGetPrevious)
const cantGetNext = computed(() => store.cantGetNext)

const { getNotifications, markNotificationRead, markAllRead } = store

function clearNotifications() {
  markAllRead().then(() => {
    openReadAllConfirm.value = false
  })
}

function getPreviousPage() {
  getNotifications(notify.value.previousPage as number)
}

function getNextPage() {
  getNotifications(notify.value.nextPage as number)
}

getNotifications()

const interval = setInterval(
  () => {
    if (!panelIsOpen.value) {
      // don't update while panel is open
      getNotifications()
    }
  },
  NOTIFICATION_INTERVAL
)

onBeforeUnmount(() => {
  clearInterval(interval)
})
</script>

<template>
  <q-btn
    round
    flat
    color="primary"
    :icon="`mdi-bell-${unreadCount ? 'ring' : 'outline'}`"
  >
    <q-badge
      v-if="unreadCount"
      color="info"
      rounded
      floating
    >
      {{ unreadCount }}
    </q-badge>
    <q-menu v-model="panelIsOpen">
      <q-card>
        <q-card-section class="text-h6 row">
          Notifications
          <q-space />
          <q-btn
            color="primary"
            class="text-weight-bold"
            flat
            dense
            :label="t('notification.menu.allRead')"
            icon-right="mdi-check-all"
            :disabled="!unreadCount"
            @click="openReadAllConfirm = true"
          />
        </q-card-section>
        <q-list v-if="unreadCount">
          <Notification
              v-for="notification in notify.notifications"
              :key="notification.id"
              :notification="notification"
              @read="markNotificationRead(notification.id)"
          />
        </q-list>
        <q-card-section
          v-else
          class="q-pt-none"
        >
          {{ t("notification.menu.noUnreadNotifications")}}
        </q-card-section>
        <q-card-actions>
          <q-btn
            flat
            round
            icon="mdi-menu-left"
            :disabled="cantGetPrevious"
            :aria-label="t('notification.pagination.previousPageLabel')"
            @click="getPreviousPage"
          />
          <q-btn
            flat
            round
            icon="mdi-menu-right"
            :disabled="cantGetNext"
            :aria-label="t('notification.pagination.nextPageLabel')"
            @click="getNextPage"
          />
          <q-space />
          <q-btn
            color="primary"
            class="text-weight-bold"
            flat
            dense
            :label="t('notification.menu.close')"
            @click="panelIsOpen = false"
          />
        </q-card-actions>
      </q-card>
    </q-menu>
  </q-btn>
  <confirm-box
    v-model:is-open="openReadAllConfirm"
    action="allRead"
    @cancel="openReadAllConfirm = false"
    @confirm="clearNotifications"
  />
</template>

<style lang="scss" scoped>
.q-list {
  max-width: 380px;;
}
</style>
