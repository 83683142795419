<script setup lang="ts">
import { computed, ref, toRefs } from 'vue'
import { useQuasar } from 'quasar'
import { useI18n } from 'vue-i18n'
// import { useRouter } from 'vue-router'
import { useContribStore } from '@/stores/contrib'
import ULayoutDefaultUserAvatar from './ULayoutDefaultUserAvatar.vue'
import ULayoutDefaultHeaderToolbarButtonProfileSubMenu from './ULayoutDefaultHeaderToolbarButtonProfileSubMenu.vue'
import ULayoutDefaultHeaderToolbarListLanguage from './ULayoutDefaultHeaderToolbarListLanguage.vue'
import ULayoutDefaultHeaderToolbarListAppearance from './ULayoutDefaultHeaderToolbarListAppearance.vue'

const quasar = useQuasar()
const { t } = useI18n()
// const router = useRouter()
const contribStore = useContribStore()

// Props
const props = defineProps<{
  modelValue: boolean,
}>()
const { modelValue } = toRefs(props)

// Emits
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void
}>()

// Data
const showAppearanceSubMenu = ref<boolean>(false)
const showLanguageSubMenu = ref<boolean>(false)

// Computed
const showMenu = computed({
  get: (): boolean => {
    return modelValue.value
  },
  set: (value: boolean) => {
    emit('update:modelValue', value)
  }
})

// Methods
// const navToPreferences = () => {
//   router.push({name: 'Preferences'})
// }

const toggleProfileMenu = () => {
  showMenu.value = !showMenu.value
}
const toggleAppearanceSubMenu = () => {
  toggleProfileMenu()
  showAppearanceSubMenu.value = !showAppearanceSubMenu.value
}
const toggleLanguageSubMenu = () => {
  toggleProfileMenu()
  showLanguageSubMenu.value = !showLanguageSubMenu.value
}
</script>

<template>
  <q-menu
    v-model="showMenu"
    data-testid="profile-menu"
    square
    :class="{
      'bg-elevation-8': quasar.dark.isActive
    }"
  >
    <q-list style="min-width: 200px">
      <q-item data-testid="profile-edit-link">
        <q-item-section avatar>
          <u-layout-default-user-avatar />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ contribStore.displayName }}</q-item-label>
          <q-item-label caption lines="1">
            <router-link
              :to="{ name: 'profile' }"
              class="text-primary"
            >
              {{ t('menu.manage') }}
            </router-link>
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-separator />
      <q-item data-testid="appearance-submenu-button" clickable @click="toggleAppearanceSubMenu">
        <q-item-section avatar>
          <q-icon name="mdi-theme-light-dark" />
        </q-item-section>
        <q-item-section>
          {{ t('appearance.label') }}
        </q-item-section>
        <q-item-section side>
          <q-icon name="mdi-chevron-right" />
        </q-item-section>
      </q-item>
      <q-item data-testid="language-submenu-button" clickable @click="toggleLanguageSubMenu">
        <q-item-section avatar>
          <q-icon name="mdi-translate" />
        </q-item-section>
        <q-item-section>
          {{ t('language.label') }}
        </q-item-section>
        <q-item-section side>
          <q-icon name="mdi-chevron-right" />
        </q-item-section>
      </q-item>
      <!--<q-item
        data-testid="preferences-button"
        clickable
        @click="navToPreferences"
        :aria-label="t('preferences.manage')"
      >
        <q-item-section avatar>
          <q-icon name="mdi-cog-outline" />
        </q-item-section>
        <q-item-section>
          {{ t('preferences.label') }}
        </q-item-section>
      </q-item>-->
      <q-separator />
      <q-item data-testid="logout-button" clickable @click="contribStore.logOut()">
        <q-item-section avatar>
          <q-icon name="mdi-logout-variant" />
        </q-item-section>
        <q-item-section>
          {{ t('account.logout') }}
        </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
  <u-layout-default-header-toolbar-button-profile-sub-menu
    v-model="showAppearanceSubMenu"
    :title="t('appearance.label')"
    data-testid="appearance-submenu"
    @click-back="toggleProfileMenu"
  >
    <u-layout-default-header-toolbar-list-appearance />
  </u-layout-default-header-toolbar-button-profile-sub-menu>
  <u-layout-default-header-toolbar-button-profile-sub-menu
    v-model="showLanguageSubMenu"
    :title="t('language.label')"
    data-testid="language-submenu"
    @click-back="toggleProfileMenu"
  >
    <u-layout-default-header-toolbar-list-language />
  </u-layout-default-header-toolbar-button-profile-sub-menu>
</template>
