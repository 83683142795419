<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

type Props = {
  mobile?: boolean
}
withDefaults(defineProps<Props>(), {
  mobile: false,
})

const { t } = useI18n()

const route = useRoute()
const params = computed(() => ({from: route.path}))
</script>

<template>
  <q-btn
    color="primary"
    class="text-weight-bold"
    flat
    dense
    :label="mobile ? undefined : t('account.login')"
    :icon="mobile ? 'mdi-login' : undefined"
    :round="mobile"
    :to="{ name: 'login', params }"
  />
</template>
