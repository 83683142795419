import { defineStore } from 'pinia'

import { publicAxios } from '@/services/authService'
import { getPaginatedResult } from '@/stores/helpers'
import {
  deleteFile,
  deleteItem,
  deleteItems,
  deleteItemThumbnail,
  getItem,
  getSetItems,
  patchItem,
  patchMediaFile,
  postItem,
  postItemCsvImport,
  postItemFile, postItemRti,
  postItemThumbnail,
  postUrl,
} from '@/services/itemsService'
import { useSetStore } from './set'
import { notifyError, notifySuccess } from '@/services/alertService'
import { t } from '@/plugins/i18n'

import type {
  DuplicationTarget,
  ItemStoreState,
  NewItem,
  PountItem,
  SetItem
} from '#/item'
import type { MediaFile, RendererName } from '#/file'

const initItemStore = (): ItemStoreState => ({
  current: initItem(),
  setItems: [],
  setItemsCount: 0,
  pageCount: 1
})

const initItem = (): NewItem | PountItem => ({
  description: '',
  isPublic: false,
  metadata: [],
  title: '',
})

const SUPPORTED_3D_EXTENSIONS = ['ply', 'nxz', 'nxs']

const FILE_ICON_MAP: Record<RendererName, string> = {
  Audio: 'mdi-file-music',
  Image: 'mdi-file-image',
  Model: 'mdi-video-3d',
  Pdf: 'mdi-file-pdf-box',
  Rti: 'mdi-camera-iris',
  Video: 'mdi-camera-iris',
}

const RENDERED_CONTENT_TYPE = Object.keys(FILE_ICON_MAP).map(fileType => fileType.toLowerCase())

/**
 * @return file type that pount can render
 */
function getFileComponent(name: string, contentType: string): RendererName | null {
  // https://stackoverflow.com/a/12900504
  // const fileExtension = name.match(/(?<=\.)[^.]+$/)[0]
  const fileExtension = name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
  let component: RendererName | null = null
  if (SUPPORTED_3D_EXTENSIONS.includes(fileExtension)) {
    component = 'Model'
  } else if (fileExtension === 'pdf') {
    component = 'Pdf'
  } else {
    const mainType = contentType.match(/.*(?=\/)/)
    if (mainType && RENDERED_CONTENT_TYPE.includes(mainType[0])) {
      component = mainType[0][0].toUpperCase() + mainType[0].slice(1).toLowerCase() as RendererName
    }
  }
  return component
}


export { FILE_ICON_MAP, initItem }

export const useItemStore = defineStore('Item', {
  state: () => initItemStore(),
  getters: {
    itemId: (state) => (state.current as PountItem).id,
    set: (state) => (state.current as PountItem).set,
    templateId: (state) => (state.current as PountItem).set.templateId,
    projectId: (state) => (state.current as PountItem).set.projectId,
    canEdit: (state): boolean => Boolean((state.current as PountItem).canEdit),
    metadata: (state) => (state.current as PountItem).metadata,
    files: (state) => (state.current as PountItem).mediaFiles,
    fileView: (state): MediaFile | null => {
      const {viewer, mediaFiles} = (state.current as PountItem)
      return (viewer && mediaFiles && viewer.fileId )
          ? <MediaFile>mediaFiles.find((file) => file.id === viewer.fileId)
          : null
    },
    isDownloadable: (state) => Boolean((state.current as PountItem).viewer?.isDownloadable),
  },
  actions: {
    addMediaFile(mediaFile: MediaFile) {
      (this.current as PountItem).mediaFiles.unshift(mediaFile)
    },
    async fileDelete(fileId: string) {
      const fromItemId = this.itemId
      if (fromItemId) {
        const removeViewer = this.fileView?.id === fileId
        await deleteFile(fromItemId, fileId)
            .then(async () => {
              const item = this.current as PountItem
              item.mediaFiles = item.mediaFiles.filter((file) => file.id !== fileId)
              if (removeViewer) {
                await this.itemUpdate({ viewer: null })
              }
            })
      }
    },
    /**
     *
     * @param {File} file
     * @returns {Promise<*>}
     */
    fileUpload(file: File) {
      const itemId = this.itemId as string
      const { name: filename, type: contentType} = file
      // request pre-signed post url
      return postUrl(itemId, filename, contentType)
        .then((response) => {
          const {url, fields} = response as {url: string, fields: Record<string, string>}
          const formData = new FormData()
          formData.append("Content-Type", contentType)
          Object.entries(fields).forEach(([k, v]) => {
            formData.append(k, v)
          });
          formData.append("file", file)
          // post file to s3 storage
          return publicAxios.post(url, formData)
            .then(() => {
              const component = getFileComponent(filename, contentType)
              // link file to item (mediaFile creation)
              return postItemFile(itemId, filename, component)
                .then((mediaFile: MediaFile) => {
                  this.addMediaFile(mediaFile)
                  notifySuccess(t('file.upload.success', { filename }))
                  return 100
                })
            })
        })
        .catch((error) => {
          notifyError(t('file.upload.error', {filename}), error)
          return 0
      })
    },
    /** Create an Item for current project and load it into current item. */
    itemCreate(item: NewItem) {
      const setStore = useSetStore()
      const { setId } = setStore
      const itemData = {...item, setId}

      return postItem(itemData)
        .then(this.setCurrentItem)
        .catch((error) => {
          notifyError(t('notify.error.item.create'), error)
        })
    },
    itemDelete(itemId: string) {
      return deleteItem(itemId)
        .then(() => {
          this.setCurrentItem(initItem())
          notifySuccess(t('notify.delete.success'))
        })
        .catch((error) => {
          notifyError(t('notify.error.item.delete', 1), error)
        })
    },
    async itemCSVImport(file: File) {
      const setStore = useSetStore()
      const { setId } = setStore
      const formData = new FormData()
      formData.append("Content-Type", file.type)
      formData.append("csv", file)
      formData.append("set_id", setId as string)
      return await postItemCsvImport(formData)
    },
    /**
     * Create a copy of current item in the same set
     * changing its title and resetting associated files
     */
    itemDuplicate(target?: DuplicationTarget) {
      const item = this.current as PountItem
      const {title: oldTitle, description, isPublic} = item
      const title = `${t('item.copy.prefix')}${oldTitle}`

      const metadata = (target?.metadata ? target.metadata : item.metadata)
        .map((metadata) => {
          if ('label' in metadata) delete metadata.label
          return metadata.name === 'title'
            ? { ...metadata, value: title}
            : metadata
        })
      const setId = target?.setId ? target.setId : item.set.id
      if (setId === undefined) {
        throw new TypeError('setId is mandatory, see no orphan item policy')
      }

      const itemData = {
        description,
        isPublic,
        metadata,
        setId,
        title,
      }
      return postItem(itemData)
        .then((fetchedItem) => {
          this.setCurrentItem(fetchedItem)
          this.router.push({ name: 'itemViewer', params: { itemId: fetchedItem.id } })
        })
        .catch((error) => {
          notifyError(t('notify.error.item.create'), error)
        })
    },
    /** delete a list of items */
    itemMultiDelete(itemIds: string[]) {
      return deleteItems(itemIds)
          .then(() => {
            this.setItems = this.setItems.filter((item: SetItem) => !itemIds.includes(item.id))
            notifySuccess(t('notify.delete.success'))
          })
          .catch((error) => {
            notifyError(t('notify.error.item.delete', itemIds.length), error)
          })
    },
    /** Retrieve from api, item of given id and load it into current item. */
    itemLoad(itemId: string) {
      return getItem(itemId)
          .then((item) => {
            this.setCurrentItem(item)
          })
          .catch((error) => {
            notifyError(t('notify.error.item.load'), error)
          })
    },
    /** Update current item fields, one or many among title, description and publisher */
    itemUpdate(itemPatch: Partial<PountItem>) {
      const itemId = (this.current as PountItem).id as string
      return patchItem(itemId, itemPatch)
          .then((item) => {
            this.setCurrentItem(item)
            notifySuccess(t('item.updated'))
          })
          .catch((error) => {
            notifyError(t('notify.error.update'), error)
          })
    },
    async mediaFileUpdate(mediaFileId: string, mediaPatch: Partial<MediaFile>) {
      const updatedMediaFile = await patchMediaFile(this.itemId, mediaFileId, mediaPatch);
      const itemFiles = (this.current as PountItem).mediaFiles
      itemFiles.splice(itemFiles.findIndex(f => f.id === updatedMediaFile.id), 1, updatedMediaFile);
    },
    rtiUpload(file: File) {
      const filename = file.name
      const itemId = this.itemId as string
      const formData = new FormData()
      formData.append("Content-Type", file.type)
      formData.append("component", "Rti")
      formData.append("file", file)
      return postItemRti(itemId, formData)
          .then((mediaFile: MediaFile) => {
            this.addMediaFile(mediaFile)
            notifySuccess(t('file.upload.success', { filename }))
            return 100
          }).catch(error => {
            notifyError(t('file.upload.error', {filename}), error)
            return 0
          })
    },
    setCurrentItem(item: PountItem | NewItem) {
      this.current = item
    },
    setItemsLoad(page = 1, size = 0) {
      const setStore = useSetStore()
      const setId = setStore.setId
      return getSetItems(setId, page, size)
          .then((paginatedItems) => {
            const {pageContent, pageCount, total} = getPaginatedResult<SetItem>(paginatedItems, page, size)
            this.setItems = pageContent
            this.setItemsCount = total
            this.pageCount = pageCount
            return total
          })
          .catch((error) => {
            notifyError(t('notify.error.item.all'), error)
          })
    },
    async thumbnailUpdate(objectId: string, file: File) {
      const { name: filename } = file
      const formData = new FormData()
      formData.append("Content-Type", file.type)
      formData.append("file", file)
      await postItemThumbnail(objectId, formData)
          .then((thumbnails) => {
            (this.current as PountItem).thumbnails = thumbnails
            notifySuccess(t('file.upload.success', {filename}))
          })
          .catch((error) => {
            notifyError(t('file.upload.error', {filename}), error)
          })
    },
    thumbnailDelete(objectId: string) {
      return deleteItemThumbnail(objectId)
        .then(() => {
          (this.current as PountItem).thumbnails = {};
          notifySuccess(t('file.delete.success'));
        })
        .catch((error) => {
          notifyError(t('file.delete.error'), error)
        })
    },
  },
})
