import { Notify } from 'quasar'
import { t } from '@/plugins/i18n'
import { AxiosError } from 'axios'

function messageToStringArray(message: string | string[] = []): string[] {
  return Array.isArray(message)
      ? message.map((m: string) => m.toLocaleString())
      : [ (message as string).toLocaleString() ]
}

function getErrorDetail(error: Error | AxiosError): string[] {
  const errorMessages = []
  if (error instanceof AxiosError) {
    if (error.response) {
      const { status, statusText, data } = error.response
      errorMessages.push(t('error.response', {status}))
      if (Array.isArray(data)) {
        errorMessages.push(...data)
      } else {
        const { detail, error: description, errors } = data as Record<string, string>
        if (Array.isArray(errors)) {
          errorMessages.push(...errors)
        } else {
          errorMessages.push(detail || description || statusText)
        }
      }
    }
    else if (error.request) {
      errorMessages.push(t('error.timeout'))
    }
  } else {
    errorMessages.push(error.toString())
  }
  return errorMessages
}

const notifyError = (message: string, error?: Error | AxiosError) => {
  const caption = error ? getErrorDetail(error).join('\n') : 'No caption'
  Notify.create({
    caption,
    message,
    multiLine: true,
    position: 'top',
    type: 'negative'
  })
}

const notifySuccess = (message: string) => {
  Notify.create({
    message,
    multiLine: true,
    position: 'top',
    type: 'positive'
  })
}

const notifyWarning = (message: string) => {
  Notify.create({
    message,
    multiLine: true,
    position: 'top',
    type: 'warning'
  })
}

const notifyInfo = (message: string) => {
  Notify.create({
    message,
    multiLine: true,
    position: 'top',
    type: 'info'
  })
}

export {
  messageToStringArray,
  getErrorDetail,
  notifyError,
  notifySuccess,
  notifyWarning,
  notifyInfo,
};


