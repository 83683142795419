import { getUnreadNotifications, patchMarkReadAllNotifications, patchMarkReadNotification } from '@/services/notificationsService'
import { defineStore } from 'pinia'
import type { NotificationStoreState } from '#/notification'
import { notifyError } from '@/services/alertService'
import { t } from '@/plugins/i18n'

/**
 * @typedef {Object} AppNotification
 * @property {number} id
 * @property {string} title
 * @property {string} timesince
 * @property {boolean} unread
 */

export const NOTIFICATION_INTERVAL = import.meta.env.NODE_ENV === 'test'
  ? 100
  : 60 * 1000

export const useDialogStore = defineStore('Dialog', {
  state: (): NotificationStoreState => ({
    notify: {
      /**
       * @type {number|null}
       */
      previousPage: null,
      /**
       * @type {number|null}
       */
      nextPage: null,
      count: 0,
      /**
       * @type {AppNotification[]}
       */
      notifications: [],
    },
  }),
  getters: {
    unreadCount: (state) => state.notify.count,
    cantGetPrevious: (state) => !state.notify.previousPage,
    cantGetNext: (state) => !state.notify.nextPage,
  },
  actions: {
    getNotifications(page = 1) {
      return getUnreadNotifications(page)
          .then((responseData) => {
            const { results: notifications, ...pageAndCount} = responseData
            this.notify = {
              ...pageAndCount,
              notifications,
            }
          })
          .catch((error) => {
            notifyError(
              t('notification.error.couldNotFetchNotifications'),
              error,
            )
          })
    },
    markNotificationRead(notificationId: number) {
      return patchMarkReadNotification(notificationId)
          .then((responseData) => {
            const { results: notifications, ...pageAndCount} = responseData
            this.notify = {
              ...pageAndCount,
              notifications,
            }
          })
          .catch((error) => {
            notifyError(
              t('notification.error.markRead'),
              error
            )
          })
    },
    markAllRead() {
      return patchMarkReadAllNotifications()
          .then(() => {
            this.$reset()
          })
          .catch((error) => {
            notifyError(
              t('notification.error.markAllRead'),
              error
            )
          })
    },
  },
})
