import { defineStore } from 'pinia'

import {
  getProfile,
  patchEmail,
  patchPassword,
  patchProfile,
  postExternalUserInvitation,
  postInternalUserInvitation,
  postInvitedUser,
  postResetPassword,
} from '@/services/usersService'
import { getUrlFromOrigin, setNewLocation } from '@/stores/helpers'
import { authAxios } from '@/services/authService'
import { useGroupStore } from './group'
import { notifyError, notifySuccess } from '@/services/alertService'
import { t } from '@/plugins/i18n'

import type { RouteLocationRaw } from "vue-router"
import type { AxiosError } from 'axios'
import type { Password, UserInvitation, UserProfile, UserStoreState } from '#/user'
import type { Credentials } from '@/services/authService'

export const initUserInfo = () => ({
  bio: '',
  institution: '',
  researchLabs: [],
  otherAffiliations: []
})

const initUserProfile = (): UserProfile => ({
  id: '',
  username: '',
  baseUsername: '',
  firstName: '',
  lastName: '',
  email: '',
  isProjectCreator: false,
  isContributor: false,
  isExternal: false,
  isAdmin: false,
  info: initUserInfo(),
  settings: {}
})

const initContribStoreState = (): UserStoreState => ({
  profile: initUserProfile(),
  isLogged: false,
})


export const useContribStore = defineStore('Contrib', {
  state: () => initContribStoreState(),
  getters: {
    myId: (state) => state.profile.id,
    displayName: (state) => `${state.profile.firstName} ${state.profile.lastName}`,
    userFirstLetter: (state) => state.profile.firstName[0],
    isCreator: (state) => state.profile.isProjectCreator,
    isContributor: (state) => state.profile.isContributor,
    isExternal: (state) => state.profile.isExternal,
    email: (state) => state.profile.email,
    editable: (state) => {
      const { firstName, lastName, info } = state.profile
      return { firstName, lastName, info }
    },
    settings: (state) => state.profile.settings,
  },
  actions: {
    /** Request local account creation, project affiliation and sign user in if successful */
    async createInvitedUser(userInvitation: UserInvitation) {
      await postInvitedUser(userInvitation)
        .then(async (userProfile) => {
            this.profile = userProfile
            const { newPassword: password, email: username, projectId } = userInvitation
            const projectRoute: RouteLocationRaw = {
              name: 'projectDetail',
              params: { projectId: projectId },
              query: { welcome: 'true' }
            }
            await this.logIn(projectRoute, {password, username})
        })
        .catch((error) => {
            notifyError(t('notify.error.createUser'), error as AxiosError)
        })

    },
    /** Request to send an email inviting external user to a project and notify it success */
    async inviteExternalUser(projectId: string, email: string) {
      const url = getUrlFromOrigin('/invitation')
      await postExternalUserInvitation({ url, email, projectId })
          .then(() => {
            notifySuccess(t('mail.success', { email }))
          })
          .catch((error) => {
            notifyError(t('mail.failure', { email }), error)
          })
    },
    /** Invite internal user to a project and notify it success */
    async inviteInternalUser(projectId: string, username: string) {
      const projectUrl = getUrlFromOrigin(`/projects/${projectId}/`)
      await postInternalUserInvitation({ username, projectId, url: projectUrl })
          .then(({ user, email }) => {
            const groupStore = useGroupStore()
            groupStore.addProjectMember(user)
            if (email === 'sent') {
              notifySuccess(t('group.members.mail.sent'))
            } else if (email === 'error') {
              notifySuccess(t('group.members.mail.error'))
            }
          })
          .catch((error) => {
            notifyError(t('invite.failure', {username}), error as AxiosError)
          })
    },
    /**
     * @param refresh - force profile load
     */
    async loadProfile(refresh = false) {
      if (this.isLogged && !refresh) return
      await getProfile()
          .then(({info, ...profile}) => {
            profile.info = {
              ...initUserInfo(),
              ...info
            }
            this.profile = profile
            this.isLogged = true
          })
          .catch((error) => {
            notifyError(t('notify.error.selfProfile'), error)
          })
    },
    async logIn(route: RouteLocationRaw, credentials: Credentials) {
      try {
        await authAxios.logIn(credentials)
        await this.loadProfile()
        await this.router.replace(route)
      } catch (error) {
        notifyError(t('notify.error.login'), error as AxiosError)
      }
    },
    logOut(path = ''){
      authAxios.logOut()
      this.isLogged = false
      const redirectUrl = getUrlFromOrigin(path)
      setNewLocation(`${import.meta.env.VITE_APP_AXIOS_BASE_URL}logout/?redirect=${redirectUrl}`)
    },
    async updateProfile(profile: Partial<UserProfile>) {
      try {
        this.profile = await patchProfile(profile)
        notifySuccess(t('profile.update.success'))
      } catch (error) {
        notifyError(t('profile.update.failure'), error as AxiosError)
      }
    },
    async updateMail(email: string) {
      try {
        this.profile = await patchEmail(email)
        notifySuccess(t('profile.update.success'))
      } catch (error) {
        notifyError(t('profile.update.failure'), error as AxiosError)
      }
    },
    async updatePassword(password: Password): Promise<void> {
      await patchPassword(password)
    },
    async resetPassword(email: string) {
      const url = getUrlFromOrigin('/reset-password')
      try {
        await postResetPassword({email, url})
        notifySuccess(t('mail.success', {email}))
      } catch (error) {
        notifyError(t('mail.failure', {email}), error as AxiosError)
      }
    },
  },
})

