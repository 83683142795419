<script setup lang="ts">
import { useLocale } from '@/composables/useLocale'

const { localesArray, setLocale } = useLocale()
</script>

<template>
  <q-item
    v-for="(locale, index) in localesArray"
    :key="index"
    clickable
    :aria-selected="locale.isActive"
    @click="setLocale(locale.id)"
    v-close-popup
  >
    <q-item-section avatar>
      <q-icon
        v-if="locale.isActive"
        name="mdi-check"
      />
    </q-item-section>
    <q-item-section>{{ locale.label }}</q-item-section>
  </q-item>
</template>
