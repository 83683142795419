<script setup lang="ts">
import { useQuasar } from 'quasar'
import { computed } from 'vue';

const quasar = useQuasar()

const isDark = computed(() => quasar.dark.isActive)
const icons = computed(() => ({ content: ['us-share'] }))
</script>

<template>
  <div
    class="sun sun-gray-darker signature"
    :class="isDark ? 'signature__dark' : ''"
  >
    <div class="sun-row-container">
      <span class="sun-row sun-2x">
        <span class="sun-cell">
          <span class="sun-cell-inner">
            <i
              v-for="(icon, index) in icons.content"
              :key="`signature-icon-${index}`"
              class="us"
              :class="icon"
            />
          </span>
        </span>
      </span>
      <span class="sun-row-container">
        <span class="sun-row">
          <router-link
            class="sun-cell"
            :to="{ name: 'Home' }"
            title="Pount"
          >
            <span class="sun-cell-inner">
              {{ $t('signature.long') }}
            </span>
          </router-link>
          <!-- <span class="sun&#45;cell sun&#45;empty&#45;24" /> -->
          <!-- <span class="sun&#45;cell sun&#45;empty&#45;8" /> -->
        </span>
        <span class="sun-row">
          <a
            class="sun-cell"
            href="https://www.unistra.fr"
            :title="$t('legal.editor.name')"
          >
            <span class="sun-cell-inner">
              {{ $t('legal.editor.name') }}
            </span>
          </a>
        </span>
      </span>
    </div>
  </div>
</template>
