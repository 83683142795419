import { publicAxios, authAxios } from '@/services/authService'
import type { PountSet, SetPatch, SetSearchSetting } from '#/set'

export const getPublicSet = async (id: string) => {
  const response = await publicAxios.get(`sets/${id}/`)

  return response.data
}

export const getPublicSetList = async (projectId: string, page: number, size: number) => {
  const response = await publicAxios.get(`sets/?project_id=${projectId}&page=${page}&page_size=${size}`)

  return response.data
}

export const searchPublicSets = async (query: string, pageSize: number, page: number, ordering: string) => {
  const response = await publicAxios.get(`sets/search/?query=${query}&page_size=${pageSize}&page=${page}&ordering=${ordering}`)

  return response.data
}

export const postSet = async (set: Partial<PountSet>) => {
  const response = await authAxios.post('sets/', set)

  return response.data
}

export const getSet = async (setId: string) => {
  const response = await authAxios.get(`sets/${setId}/`)

  return response.data
}

export const getProjectSets = async (page: number, size: number, projectId: string) => {
  const response = await authAxios.get(`sets/?project_id=${projectId}&page=${page}&page_size=${size > 0 ? size : 0}`)

  return response.data
}

export const searchSets = async (query: string, pageSize: number, page: number, ordering: string) => {
  const response = await authAxios.get(`sets/search/?query=${query}&page_size=${pageSize}&page=${page}&ordering=${ordering}`)

  return response.data
}

export const deleteSet = async (setId: string) => {
  const response = await authAxios.delete(`sets/${setId}/`)

  return response.data
}

export const deleteSets = async (setIds: string[]) => {
  const response = await authAxios.delete(`sets/`, { data: { setIds } })

  return response.data
}

export const patchSet = async (setId: string, setData: SetPatch) => {
  const response = await authAxios.patch(`sets/${setId}/`, setData)

  return response.data
}

export const postSetThumbnail = async (objectId: string, thumbnailData: FormData) => {
  const response = await authAxios.post(`sets/${objectId}/thumbnail/`, thumbnailData)

  return response.data.thumbnails
}

export const deleteSetThumbnail = async (objectId: string) => {
  const response = await authAxios.delete(`sets/${objectId}/thumbnail/`)

  return response.data
}

export const getSetSearchSettings = async (setId: string): Promise<SetSearchSetting[]> => {
  const response = await authAxios.get(`sets/${setId}/search-config/`)

  return response.data
}

export const patchSetSearchSettings = async (setId: string, setSearchSettings: SetSearchSetting[]): Promise<SetSearchSetting[]> => {
  const response = await authAxios.put(`sets/${setId}/search-config/`, setSearchSettings)

  return response.data
}
