<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useQuasar } from 'quasar'
// import { useLocale } from '@/composables/useLocale'
import { useAppearance } from '@/composables/useAppearance'
import { darkBrandColors as dark, lightBrandColors as light, setBrandColors } from '@/assets/style/brandColors'
import ULayoutDefaultHeader from './ULayoutDefaultHeader.vue'
import ULayoutDefaultNavDrawer from './ULayoutDefaultNavDrawer.vue'
import ULayoutDefaultFooter from './ULayoutDefaultFooter.vue'
import { useLocale } from '@/composables/useLocale'

const quasar = useQuasar()
const { setDefaultQuasarLocale } = useLocale()
const { setDefaultQuasarMode } = useAppearance()

// TODO : unit test this watcher
watch(() => quasar.dark.isActive, (isActive) => {
  setBrandColors(isActive ? dark : light)
})

onMounted(async () => {
  await setDefaultQuasarMode()
  await setDefaultQuasarLocale()
})
</script>

<template>
  <q-layout>
    <u-layout-default-header />
    <u-layout-default-nav-drawer />

    <q-page-container>
      <slot />
    </q-page-container>

    <u-layout-default-footer />
  </q-layout>
</template>

<script lang="ts">
export default {
  name: 'ULayoutDefault',
};
</script>

<style lang="scss" scoped>
:slotted(.q-page) {
  padding: 12px;
  display: grid;
  grid-template-areas:
    ". content .";
  @media (max-width: $breakpoint-xs-max) {
    grid-template-columns: 100%;
    grid-template-areas: "content";
  }
  @media (min-width: $breakpoint-sm-min) {
    grid-template-columns: auto 90% auto;
  }
  @media (min-width: $breakpoint-md-min) {
    grid-template-columns: auto 85% auto;
  }
  @media (min-width: $breakpoint-lg-min) {
    grid-template-columns: auto 80% auto;
  }
}
</style>
