export const en = {
  about: {
    header: {
      intro: `
        Committed to open science, the University of Strasbourg developed the POUNT platform, 
        an open, modular and interoperable ecosystem for describing and visualising research data. 
      `,
      allows: 'Features:',
      possible: {
        save: 'Save and version data (documents, images, videos, 3D models);',
        structure: `
          Structure data following the standards of a discipline, or customise these standards with extra metadata;
        `,
        config: `
          Configure read and edit rights for the different communities;
        `,
        value: 'Enhance data, view it seamlessly and enrich it with contextual information.',
      },
    },
    mail: 'If you have a question or need help, please contact the POUNT team by e-mail:',
    objective: {
      headline: 'Goal',
      content: `
          Complying to FAIR principles (Findable, Accessible, Interoperability, Reuse), 
          <b>POUNT</b> is a platform that first of all allows researchers from the University of Strasbourg
          to submit data sets from their research work. 
          It is designed to enable researcher to manage these data sets independently and contextualize them.
          This cross-disciplinary platform, offers visualization tools such as a 3D model viewer.<br>
          Researchers may invite people from outside the University of Strasbourg as contributors.
          It also allows to share these data sets with restricted or public access and
          public content is accessible to everyone.
          It has also been designed in such a way that it can be distributed to any institution.
        `,
    },
    title: 'About',
  },
  account: {
    login: 'Login',
    logout: 'Logout',
  },
  appearance: {
    dark: 'Dark',
    label: 'Appearance',
    light: 'Light',
    selection: 'Select theme'
  },
  btn: {
    add: 'Add',
    all: 'All',
    back: 'Back',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    delete: 'Delete',
    download: 'Download',
    edit: 'Edit',
    help: 'Help',
    insert: 'Insert',
    modify: 'Modify',
    open: 'Open',
    preview: 'Preview',
    remove: 'Remove',
    reset: 'Reset',
    save: 'Save',
    saveAndClose: 'Save and close',
    saveAndContinue: 'Save and continue',
    send: 'Send',
    share: {
      on: 'Set Public',
      off: 'Set Private'
    },
    signUp: 'Sing Up',
    submit: 'Submit',
    update: 'Update',
    validate: 'Validate',
    visualize: 'Visualize',
  },
  confirm: {
    allRead: '@:confirm.areYouSure mark all your notifications as "read" ? ',
    areYouSure: 'Are you sure you want to',
    delete: '@:confirm.areYouSure delete {object} ?',
    dismiss: '@:confirm.areYouSure remove {object} from this project managers list ?',
    dismissMyself: '@:confirm.areYouSure remove yourself from this project managers list ?',
    promote: '@:confirm.areYouSure add {object} to this project managers list ?',
    quitPount: 'You are about to quit pount. Do you want to continue ?',
    quitPountTitle: 'Confirm your navigation',
    remove: '@:confirm.areYouSure remove {object} from the project ?',
    selection: 'your selection',
    title: 'Please confirm',
  },
  copiedToClipboard: 'Copied to clipboard',
  copyToClipboard: 'Copy to clipboard',
  createdOn: 'Created on {date}',
  dashboard: {
    creatorForm: "Access the form",
    creatorFormMessage: "To start creating your projects and describing your data, fill out the project creation request form.",
    label: 'Dashboard',
    project: {
      delete: 'Delete selected projects',
    },
  },
  description: 'description',
  detail: 'Detail',
  edit: 'edit',
  error: {
    image: 'The image could not be loaded',
    internal: 'Internal Error',
    response: '{status} Error: ',
    timeout: 'Server timeout',
    undefined: 'Unknown error',
  },
  file: {
    add: 'Add a file',
    delete: {
      tooltip: 'Delete this file',
      error: 'An error occured during the deletion of the file.',
      success: 'The file has been deleted.',
    },
    designate: 'the "{name}" file',
    dialogTitle: '@:file.downloadable files',
    download: 'Download',
    downloadable: 'Downloadable',
    header: 'Files',
    name: 'Name',
    size: 'Size',
    type: 'Type',
    upload: {
      button: 'Upload',
      error: 'Upload failed for your file "{name}"',
      label: 'File input',
      success: 'Your file "{filename}" has been uploaded',
    },
    visualize: 'Visualize',
    visualized: 'Visualised',
  },
  form: {
    field: {
      description: 'Description',
      email: 'Email',
      name: 'Name',
      title: 'Title',
      username: 'Username',
    },
    rule: {
      minPassLength: 'at least 8 characters',
      newPassword: 'can not be identical to old password',
      passMatch: 'the two passwords must be the same',
      required: 'this field is required',
    },
  },
  general: "General",
  group: {
    add: {
      hint: 'Select one user or more',
      title: 'Add someone',
    },
    create: 'Create a group',
    default: {
      managers: 'Managers',
      members: 'Members',
    },
    designate: '"{name}" group',
    edit: 'Edit group',
    empty: 'This group does not contain any user yet.',
    label: 'Group | Groups',
    leave: 'quitter',
    manage: {
      permissions: 'Update permissions',
    },
    members: {
      actual: 'Contributors list',
      add: 'click to add',
      available: 'Available project\'s members',
      count: 'No member | one member | {count} members',
      edit: 'Update members',
      invite: 'Invite',
      inviteUser: '@:group.members.invite a user',
      mail: {
        error: 'User couldn\'t be noticed by email',
        sent: 'User will be noticed by email',
      },
      project: 'Project members',
      remove: 'click to remove',
      self: 'Group members',
    },
    new: 'New groupe',
    none: 'No group.',
    project: 'Project groups',
    right: {
      actual: 'Sets avalaible to contributors',
      add: 'Add a Set',
      update: 'Update contribution rights',
    },
    sets: 'Contribution sets',
    tab: 'Groups',
    update: 'Update group {name}',
  },
  hop: {
    camera: {
      orthographic: 'Orthographic Camera',
      perspective: 'Perspective Camera',
    },
    close: 'Close toolbar',
    fullscreen: {
      exit: 'Exit Full Screen',
      request: 'Full Screen',
    },
    help: 'Hide help',
    light: {
      off: 'Disable Lighting',
      on: 'Enable Lighting',
      source: {
        lock: 'Disable Light Control',
        move: 'Enable Light Control',
      },
    },
    measure: {
      label: 'Measured length',
      help: 'Measure tool',
    },
    point: {
      label: 'XYZ picked point',
      help: 'Point picking',
    },
    reset: 'Position reset',
    sections: {
      flip: {
        label: 'Flip',
        title: 'Flip {axis} axis section direction',
      },
      help: 'Plane Sections',
      plane: {
        label: 'Plane',
        title: '{axis} Axis section',
        on: 'Enable @:hop.sections.plane.title',
        off: 'Disable @:hop.sections.plane.title',
      },
      position: {
        label: 'Position',
        title: 'Move {axis} Axis Section Position',
      },
      showPlanes: {
        label: 'Show planes',
        title: 'Show section Planes',
      },
      showEdges: {
        label: 'Show edges',
        title: 'Show section Edges',
      },
    },
    screenshot: 'Save Screenshot',
    texture: {
      disable: 'Enable Solid Color',
      restore: 'Disable Solid Color',
    },
    zoom: {
      plus: 'Zoom In',
      minus: 'Zoom Out',
    },
  },
  invite: {
    title: 'Invite user',
    success: '{username} has been affiliated with this project',
    failure: 'Could not affiliate {username} with this project',
  },
  item: {
    backToSet: 'Back to set',
    copy: {
      inTarget: 'in this set : "{values}"',
      toSet: 'Into "{title}" set',
      move: 'Move',
      duplicate: 'Copy',
      title: 'Copy @:item.designate',
      here: 'Copy here',
      ignored: {
        field: 'This field will be ignored. | Those fields will be ignored.',
        value: `This item value will be ignored, field is missing in this set.
         | Those item values will be ignored, fields are missing in this set.`,
      },
      impossible: 'Copy impossible, ',
      required: `@:item.copy.impossible this field is required. 
        | @:item.copy.impossible those fields are required.`,
      partial: 'Warning: Only partial copy available.',
      inProject: 'Choose set',
      projectsLoad: 'Load other projets',
      prefix: 'Copy of ',
      reason: {
        unknownOption: `This option is unknown @:item.copy.inTarget
         | Those options are unknown @:item.copy.inTarget`,
        typeMismatch: 'Target field type do not match with item data',
        tooManyValues: 'This field receive tow many values, field limit : {limit}',
        missingValue: 'Missing data.',
      },
    },
    count: 'No item | {count} item | {count} items',
    create: 'Create an item',
    submitted: 'Submitted by:',
    description: 'Presentation',
    designate: 'this item',
    destroy: 'Delete item',
    destroy_text: 'This will delete this item permanently. Are you sure ?',
    detail: '@:item.label @.lower:detail',
    edit: 'Item edition',
    export: 'Datacite export',
    import: {
      button: 'CSV import',
      success: '"{filename}" import succeed !',
      created: '@:item.count ajouté à l\'ensemble. | @:item.count ajoutés à l\'ensemble.',
      error: 'Items haven\'t been created from file "{filename}".',
      launch: 'Launch',
    },
    file: 'File',
    label: 'Item',
    none: 'No item.',
    metadata: 'Metadata',
    plural: 'Items',
    shareLink: 'Visualize the @.lower:item.label in Pount',
    update: 'Update item',
    updated: 'The item has been updated',
    fields: {
      title: {
        label: 'Title',
        empty: 'Untitled item',
      },
      description: {
        label: 'Description',
        empty: 'No description',
      },
      identifier: {
        label: 'Unique identifier',
        empty: 'None',
      },
      creator: {
        label: 'Author',
        empty: 'Unknown author',
      },
      contributor: {
        label: 'Contributors',
        empty: 'No known contributor',
      },
      publisher: {
        label: 'Publisher',
        empty: 'Unknown',
      },
      publicationYear: {
        label: 'Publication year',
        empty: 'Unknown',
      },
      resourceTypeGeneral: {
        label: 'Resource type general',
      },
      resourceType: {
        label: 'Resource type',
      },
      language: {
        label: 'Language',
        empty: 'Unknown',
      },
      size: {
        label: 'Size',
        empty: 'Unkown',
      },
      format: {
        label: 'Format',
        empty: 'Unknown',
      },
      version: {
        label: 'Version',
        empty: 'None',
      },
    },
    tabs: {
      copy: 'Copy',
      detail: '@:detail',
      files: 'Files',
      metadata: '@:item.metadata',
      viewer: 'Viewer',
    },
    showEmptyMetadata: 'Show empty metadata',
    hideEmptyMetadata: 'Hide empty metadata',
    delete: 'Delete this item',
    share: 'Share this item',
    exportDatacite: 'Export datacite metadata to XML file',
  },
  language: {
    label: 'Language',
    selection: 'Select language'
  },
  lastUpdate: 'Last update: {lastUpdate}',
  legal: {
    headline: 'Legal Informations',
    editor: {
      name: 'University of Strasbourg',
      headline: 'Publisher',
      property: 'This site is the property of the University of Strasbourg (4 Rue Blaise Pascal, 67081 Strasbourg).',
      complain: 'For any complaint you can send an email to',
    },
    director: {
      headline: 'Director of publication',
      content: 'The director of the publication is Mr Michel Deneken, President of the University of Strasbourg.',
    },
    credits: {
      headline: 'Credits',
      libraryService: 'University Library Service',
    },
    host: 'Hosting company',
    ownership: {
      headline: 'POUNT and Intellectual Property',
      definition: `
          The entire site is defined as a database within the meaning of the directive of 11 March 1996 and its
          transposition into French law in the law of 1 July 1998 (article L 341-1 et seq. of the intellectual property
          code).
        `,
    },
    researchData: {
      headline: 'Research data',
      content: `
          The research data collected are datasets from research projects. The data owner manages the metadata that
          contextualizes the dataset and decides on its confidentiality. Depending on the rights assigned by the
          researcher, this data will be accessible publicly or in restricted mode to user groups defined by the
          researcher. The data retention period is defined by the researcher but is indefinite for data sets resulting
          from research projects funded by the University of Strasbourg.
        `,
    },
    reuseContent: {
      headline: 'Reuse of content',
      content: `
          If there is no mention of a license to use and reuse the data on a project page, a set page or an item page, 
          the Internet user must respect the intellectual property rights on the content posted online. Thus, except for
          exceptions to the Intellectual Property Code (and in particular the exception of a short quotation), he may not
          reproduce or represent, in whole or in part, the content posted online without the prior written consent of the
          author. Refer to each project page to find out how to reuse it.<br/>
          In any event and in view of the foregoing, the user of the site and the information it contains acknowledges
          that he/she uses it under his/her sole responsibility. The University of Strasbourg cannot therefore be held
          liable for any direct or indirect damage of any kind whatsoever resulting in whole or in part from the use of
          the information on the site.
        `,
    },
    dgpr: {
      headline: 'Protection of personal data',
      collect: `
          There is no collection of your data if you only visit the site.
          Persons from outside the University of Strasbourg who are required to contribute to a collection must fill in
          a form beforehand.<br>
          For researchers at the University of Strasbourg, the data for the creation of the account will be collected
          indirectly during the first connection to the platform using the E.N.T. identifiers.
          The data collected concerning the users who connect are:
          <i>Usual name, first name, e-mail address, connecting structure</i><br>
          This data allows the traceability of depositors as well as the ability to quote the dataset or an element.
          This data is kept until the user requests the application manager to delete an account.
        `,
      access: `
          The data of users with access to the platform are processed by the Digital Department of the University of
          Strasbourg. If contributors submit datasets, their identities and possibly their contact details will be stored
          in the metadata of the submitted data. The legal basis for the processing operation is the performance of a
          public interest task under Article 6 (1) e. of the DGPS. The processing does not lead to an automated decision
          towards users, and no data are transferred outside the European Union. In accordance with Regulation (EU)
          2016/679 of the European Parliament and of the Council of 27 April 2016 and amended Law No 78-17, users with an
          account have the right to access, rectify and delete their data. They may also request that the processing be
          limited. To exercise them, the request can be sent to: 
        `,
      dpo: `
          The University of Strasbourg has appointed a Data Protection Officer who can be reached at the following
          address: 
          <a href='mailto:dpo{'@'}unistra.fr' title="Write to the Data Protection Officer">dpo{'@'}unistra.fr</a>.
        `,
      cnil: `
          If, after contacting us, you consider that your rights on your data are not respected, you can 
          <a href='https://www.cnil.fr/fr/webform/adresser-une-plainte' title="CNIL complaint form">
            send a complaint to the CNIL
          </a>.
        `,
    },
    cookies: {
      headline: 'Cookies',
      description: 'The only cookie deposited will be used to authenticate users with an account.',
    },
    floss: {
      headline: 'POUNT is free software',
      description: `POUNT source code is governed by the terms of the
        <a href='https://www.gnu.org/licenses/gpl-3.0.html' title="GPLV3 license terms">
          GNU General Public License, version 3
        </a>.
        It means POUNT source code can be modified, distributed and used for private or commercial purposes.
        You must however document, disclose source code and include same license for any distribution and modification of this licensed material.
        `,
      repositories: `<ul>
        <li>POUNT API source code is available <a href='https://git.unistra.fr/community/pount-api' title="POUNT API repository">here</a>.</li>
        <li>POUNT client source code is available <a href='https://git.unistra.fr/community/pount-front' title="POUNT client repository">here</a>.</li>
        </ul>`,
    },
  },
  logout: 'See you soon',
  mail: {
    failure: 'email could not be sent to "{email}"',
    success: 'email successfully sent to "{email}"',
    title: 'Write an email to the POUNT team',
  },
  menu: {
    home: 'Home',
    about: 'About',
    legal: 'Legal notice',
    help: 'Help',
    settings: 'Settings',
    dark: 'Dark Theme: {mode}',
    feedback: 'Contact',
    language: 'Language',
    theme: 'Theme',
    manage: 'Manage your profile',
    userProfile: 'My profile',
    logout: 'Log out',
  },
  notFound: {
    title: 'Page not found 🙀',
    content: `
      Perhaps you are here because:
      <ul>
        <li>The page you thought exists ... doesn't</li>
        <li>The page exists but someone typed the wrong URL</li>
        <li>You were looking for your kitty and got lost</li>
        <li>You love 404 pages</li>
      </ul>
    `,
    backDashboard: 'Back to dashboard',
    backHome: 'Back to home page',
  },
  notification: {
    error: {
      couldNotFetchNotifications: 'Error while fetching the list of notifications',
      markRead: 'Error while marking the notification as read',
      markAllRead: 'Error while marking notifications as read',
    },
    menu: {
      noUnreadNotifications: 'Great, you have no unread notification',
      markAsRead: 'Mark as read',
      allRead: 'All read',
      close: 'Close',
    },
    pagination: {
      previousPageLabel: 'Previous page',
      nextPageLabel: 'Next page',
    },
  },
  notify: {
    delete: {
      request: {
        error: 'Deletion request aborted',
        success: 'Deletion request sent',
      },
      success: 'Deletion successful',
    },
    error: {
      login: 'we could not log you in with provided credentials',
      update: 'Update failed',
      createUser: 'User creation encounter an error',
      groups: {
        create: 'We could not create this groupe',
        all: 'We could not load group list',
        delete: 'We could not delete this group',
        user: {
          add: 'We could not add this member',
          remove: 'We could not remove this member',
        },
        set: {
          add: 'We could not add this set',
          remove: 'We could not remove this set',
        },
      },
      invitation: {
        validationFailed: 'Could not validate you invitation',
        dataMissing: 'Data missing to validate you invitation',
      },
      item: {
        all: 'We could not load item list',
        load: 'We could not load asked item',
        create: 'We could not create your item',
        delete: 'We could not delete this item | We could not delete those items',
      },
      project: {
        all: 'We could not load project list',
        load: 'We could not load asked project',
        create: 'We could not create your project',
      },
      searchSettings: 'Search settings update failed.',
      selfProfile: 'profile load failed',
      set: {
        all: 'We could not load set list',
        load: 'We could not load asked set',
        create: 'We could not create your set',
        delete: 'We could not delete this set | We could not delete those sets',
      },
      template: {
        all: 'We could not load template list',
        load: 'We could not load asked template',
        create: 'We could not create your template',
        delete: 'One template hasn\'t been deleted. | Some templates have not been deleted.',
        deleteAll: 'No templates have been deleted.'
      },
      referential: {
        all: 'We could not load @:referential.base list',
        create: 'We could not create your @:referential.base',
        update: 'We could not update your @:referential.base'
      }
    },
    success: {
      template: {
        delete: 'The template has been deleted. | The templates have been deleted.'
      },
      group: {
        create: 'The group has been created.',
        delete: 'The group has been deleted.',
      },
    },
    updated: {
      succeed: 'updated successfully',
      group: 'Group @:notify.updated.succeed',
      searchSettings: 'Search settings @:notify.updated.succeed',
      template: 'Template @:notify.updated.succeed',
    },
  },
  off: 'OFF',
  on: 'ON',
  pagination: {
    perPage: '{item} per page',
  },
  password: {
    label: 'Password',
    old: 'Old password',
    new: 'New password',
    confirm: 'Confirm your password',
    change: 'Change my password',
    failure: 'We could not update your password',
    success: 'Your password has been updated',
    reset: {
      title: 'Reset my password',
      explanation: `
        Please check your email address. 
        If it is associated to a POUNT account, an email will be sent
      `,
    },
    forgot: 'Forgot your password?',
  },
  private: 'Private',
  profile: {
    personalInfo: 'Personal info',
    contact: 'Contact',
    update: {
      success: 'Your profile has been updated',
      failure: 'Your profile has not been updated',
      wanted: {
        button: 'Update my profile',
        deco: 'You will be disconnected',
        failed: 'Your request aborted',
        next: 'Your profile will be updated on next connexion',
      },
    },
    changeMail: {
      title: 'Email change',
      new: 'New email',
      previous: 'Changing from: "{previousMail}"',
    },
    firstName: 'First name',
    lastName: 'Last name',
    bio: 'Bio',
    email: 'Email',
    researchLab: {
      acronym: 'Acronym',
      field: 'Research labs',
      label: 'Label',
      longName: 'Long name',
      shortName: 'Short name',
    },
    institution: 'Institution',
    affiliations: 'Affiliations',
    otherAffiliations: 'Other affiliations',
    newAffiliation: 'Add affiliation',
  },
  project: {
    name: 'Project\'s name',
    description: 'Description',
    designate: 'this project',
    detail: '@:project.label @.lower:detail',
    field: '@.capitalize:referential.label options',
    generalInfo: {
      label: 'General info',
      updated: 'General info of this project have been updated',
    },
    edit: '@:project.label edition',
    editReferential: 'Edit @.lower:referential.label',
    isPublic: 'Make project public',
    create: 'Create a project',
    self: 'My project | My projects',
    contributions: 'My contribution | My contributions',
    label: 'Project',
    plural: 'Projects',
    link: 'See',
    welcome: 'Welcome on "{name}"',
    manager: 'Manager',
    managers: 'Manager | Managers',
    promoteManager: 'Promote @.lower:project.manager',
    dismissManager: 'Dismiss @.lower:project.manager',
    addMember: 'Add a member',
    removeMember: 'Remove from project',
    removeReferential: 'Remove @.lower:referential.label',
    subtitles: {
      managers: '{label}: {names}',
    },
    contributor: 'Contributor',
    search: '@:search.title in the @.lower:project.label',
    searchInfo: 'These criteria are only applied to items',
    searchExport: 'Export search results to CSV',
    searchReverseSortOrder: 'Reverse sort order',
    moreCriteria: 'More criteria',
    viewReferential: 'View @.lower:referential.label',
    delete: 'Delete this project',
    deleteSet: 'Delete selected sets',
    deleteTemplate: 'Delete selected templates',
  },
  public: 'Public',
  referential: {
    base: 'referential | referentials',
    bind: 'Use in a project',
    create: 'Create a referential',
    createStandard: 'Create a standard referential',
    created: '@:referential.label saved with success',
    deleteSuccess: 'Referential has been deleted.',
    deleteError: 'An error occured while deleting the referential.',
    description: '@.capitalize:description',
    fromProject: 'From project:',
    label: '@.capitalize:referential.base | @.capitalize:referential.base',
    lang: {
      add: 'Add a language',
      available: 'Languages available',
      default: 'Default Language',
      required: 'A language is required',
    },
    level: {
      add: 'Add a node level',
      display: 'Node levels',
    },
    list: {
      sort: {
        name: {
          ascending: 'Name [A-Z]',
          descending: ' Name [Z-A]'
        },
        scope: {
          ascending: 'Status [base-public]',
          descending: 'Status [public-base]',
          project: {
            ascending: 'Status [base-public-project]',
            descending: 'Status [project-public-base]',
          }
        }
      },
      use: {
        confirmTitle: 'Use in project',
        confirmMessage: 'Select the project in which you want to use the referential.',
        error: 'Can\'t bind the referential to the project.',
        success: 'The referential has been bound to the project.'
      }
    },
    name: '@:referential.label name',
    node: {
      add: 'new {level} node to {parent}',
      insert: 'Insert {levelName}'
    },
    rename: 'Rename "{name}"',
    select: 'Level select',
    translations: 'Translations',
    translationsOf: 'Translations of {name}',
    tree: {
      newRoot: 'New {rootLevelName}',
      exist: 'This name is already used'
    },
    unbind: 'Unbind the referential of the project',
    unbindSuccess: 'Referential has been unbound of the project.',
    unbindError: 'An error occured while unbinding the referential.',
    updated: '@:referential.label updated'
  },
  search: {
    aggregation: {
      bucket: {
        boolean: {
          true: 'Yes',
          false: 'No',
        },
        item: '@:item.label | @:item.plural',
        set: '@:set.label | @:set.plural',
        project: '@:project.label | @:project.plural',
      },
      labels: {
        isPublic: 'Public objects',
        docType: 'Object type',
      },
    },
    label: 'Search',
    noResult: 'No result found for {query}',
    placeholder: 'Search in public data',
    title: 'Search',
    results: 'Results',
    sortOptions: 'Sort options',
    relevance: 'Relevance',
  },
  set: {
    title: 'Title',
    delete: 'Delete this set',
    description: 'Description',
    designate: 'this set',
    detail: '@:set.label @.lower:detail',
    edit: '@:set.label edition',
    generalInfo: {
      updated: 'General info of this set have been updated',
    },
    initiator: 'Started by: {name}',
    publisher: 'Publisher',
    template: 'Metadata template',
    count: 'No set | {count} set | {count} sets',
    label: 'Set',
    plural: 'Sets',
    create: 'Create a set',
    created: 'Set created',
    update: 'Update set',
    destroy: 'Delete set',
    share: 'Share this set',
    shareAllItems: 'Share all items.',
    shareCarousel: 'Carousel mode',
    shareLink: 'Visualize the @.lower:set.label in Pount',
    shareModalIntegrationCode: 'Integration code',
    shareModalOptions: 'Options',
    shareModalText: 'Select the items you want to share with the set.',
    shareOptions: 'Embed options',
    empty: 'This set does not contain any item yet.',
    none: 'No set.',
    cant_read: 'You do not have permission to see these items.',
    search: {
      addToAggregation: 'Add to aggregation',
      addToSearchFields: 'Add to search fields',
      label: '@:search.title in the @.lower:set.label',
      metadataType: 'Data type',
      settings: 'Search settings',
      types: {
        bools: 'Boolean',
        dates: 'Date',
        floats: 'Floating point number',
        keyword: 'Binary text',
        longs: 'Integer',
        strings: 'Text',
        stringsFr: 'Text french',
      },
    },
    self: 'Set',
    validation: {
      title: 'The set cannot be created due to the following error(s):',
      field_missing: 'Missing metadata: \'{field}\'.',
      field_required: 'Metadata \'{field}\' must be \'required\'.',
    },
    itemsImport: 'Import items from CSV file',
    deleteItem: 'Delete selected sets',
  },
  signIn: {
    title: 'Sign in',
    description: 'Sign in to visualize your own data with Pount.',
    creation: 'If it is your first connection, a user account will be created.',
    cookies: 'To sign in with Google or GitHub, third-party cookies must be authorized in your browser settings.',
    notMember: 'Not yet involved in?',
    join: 'Sign Up',
    continue: 'Continue with {method}',
  },
  signature: {
    short: 'POUNT',
    long: 'Plateforme OUverte Numérique Transdisciplinaire',
  },
  template: {
    csv: {
      export: 'export to CSV',
      warning: 'Warning',
      noOption: `Exported file do not contain available options of fields such as select, checkboxes...
            <br>Please keep this in mind while building your import file.`,
    },
    label: 'Template',
    plural: 'Templates',
    scopes: {
      base: 'Standard',
      global: 'Shared',
      project: 'Project',
      public: 'Public',
      set: 'Set',
    },
    edit: 'Edit this template',
    view: 'Visualize this template',
    tab: {
      project: 'Metadata templates',
      set: 'Template',
    },
    create: 'Create a template',
    new: 'New template',
    form: {
      baseTemplate: {
        label: 'Base template',
        placeholder: 'Select the base template',
      },
    },
  },
  themes: {
    light: 'Light',
    dark: 'Dark',
  },
  updated: {
    ago: 'ago',
    self: 'Updated',
    label: '@:updated.self {time} @:updated.ago',
    second: 'second | seconds',
    minute: 'minute | minutes',
    hour: 'hour | hours',
    day: 'day | days',
    month: 'month | months',
    year: 'year | years',
  },
  user: {
    self: 'User',
    internal: 'Internal',
    external: 'External',
    internalUser: '@:user.internal user',
    externalUser: '@:user.external user',
    designate: 'user "{name}"',
    welcome: 'Be welcome on POUNT!',
  },
  visibility: {
    public: 'Visible to everyone',
    restricted: 'Visible to contributors only',
  },
}
