<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const documentationUrl = import.meta.env.VITE_APP_DOCUMENTATION_URL
</script>

<template>
  <q-btn
    :aria-label="t('btn.help')"
    :href="documentationUrl"
    color="primary"
    dense
    flat
    icon="mdi-help"
    round
    size="sm"
  />
</template>
