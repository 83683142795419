import type { SearchStoreState } from "#/search";
import { getAuthSearchResults, getPublicSearchResults } from "@/services/searchService";
import { defineStore } from "pinia";

export const useSearchStore = defineStore('Search', {
  state: (): SearchStoreState => ({
    toSearch: '',
    hits: {
      value: 0,
      relation: ''
    },
    params: {},
    results: [],
    pagination: {
      page: 1,
      pageSize: 10,
    },
    aggregations: {},
    buckets: {},
  }),
  getters: {
    q: (state) => `q=${state.toSearch}`,
    getParams: (state) => Object.values(state.params).reduce((acc, param) => `${acc}&${param.key}=${param.value}`, ''),
    page: (state) => state.pagination.page,
    pageSize: (state) => state.pagination.pageSize,
    total: (state) => state.hits.value,
    getPagination: (state) => `&page=${state.pagination.page}&page_size=${state.pagination.pageSize}`,
    aggregationsArray: (state) => {
      return Object.values(state.aggregations)
    },
    aggregationValue() {
      return (filterName: string) => {
        if (this.aggregationBuckets(filterName).length === 1) return this.buckets[this.aggregationBuckets(filterName)[0]].value ? `&${filterName}=${this.buckets[this.aggregationBuckets(filterName)[0]].keyAsString || this.buckets[this.aggregationBuckets(filterName)[0]].key}`: ''
        const noValue = this.aggregationBucketsAllChecked(filterName) || this.aggregationBucketsAllUnchecked(filterName)
        return noValue ? '' : `&${filterName}=${this.aggregationBuckets(filterName).map((id) => this.buckets[id]).filter((bucket) => bucket.value).map((bucket) => bucket.keyAsString || bucket.key).join(',')}`
      }
    },
    aggregationBuckets() {
      return (filterName: string) => this.aggregations[filterName]?.buckets
    },
    aggregationBucketsAllChecked() {
      return (filterName: string) => this.aggregationBuckets(filterName)?.reduce((acc, id) => acc && this.buckets[id].value, true)
    },
    aggregationBucketsAllUnchecked() {
      return (filterName: string) => !this.aggregationBuckets(filterName)?.reduce((acc, id) => acc || this.buckets[id].value, false)
    },
    bucket() {
      return (id: string) => this.buckets[id]
    },
    bucketLabel() {
      return (id: string) => {
        const i18nBucketPath = `search.aggregation.bucket`
        const bucket = this.buckets[id]
        const { t, te } = this.i18n
        if (bucket.keyAsString) return `${t(`${i18nBucketPath}.boolean.${bucket.keyAsString}`)} (${bucket.docCount})`
        else return te(`${i18nBucketPath}.${bucket.key}`) ? `${t(`${i18nBucketPath}.${bucket.key}`, bucket.docCount)} (${bucket.docCount})` : `${bucket.key} (${bucket.docCount})`
      }
    },
    getAggregations() {
      return () => {
        return Object.values(this.aggregations).reduce((acc, { filterName }) => {
          return `${acc}${this.aggregationValue(filterName)}`
        }, '')
      }
    },
    query() {
      return `${this.q}${this.getParams}${this.getPagination}${this.getAggregations()}`
    },
    subQuery() {
      return `${this.getParams}${this.getPagination}${this.getAggregations()}`
    },
  },
  actions: {
    resetPagination() {
      this.pagination = {
        page: 1,
        pageSize: 10,
      }
    },
    resetAggregations() {
      this.aggregations = {}
    },
    resetBuckets() {
      this.buckets = {}
    },
    async loadResults(privateSearch = false, _query?: string) {
      const getSearchResults = privateSearch ? getAuthSearchResults : getPublicSearchResults
      const { results, aggregations, hits, query } = await getSearchResults(_query || this.query)
      this.results = results.map((result) => {
        let subtitles, to, icon, color
        if (result.docType === 'project') {
          const { managers } = result
          const names = managers.join(', ')
          subtitles = [
            this.i18n.t('project.subtitles.managers', { names, label: this.i18n.t('project.managers', managers.length) }),
            this.i18n.t('set.count', result.setsCount || result.publicSetsCount || 0),
            this.i18n.t('item.count', result.itemsCount || result.publicItemsCount || 0),
          ]
          to = { name: privateSearch ? 'projectSetsList' : 'projectPublic', params: { projectId: result.id } }
          icon = 'mdi-ungroup'
          color = 'accent-blue'
        } else if (result.docType === 'set') {
          const { creator: {name}, itemsCount, publicItemsCount } = result
          subtitles = [
            this.i18n.t('set.initiator', { name }),
            this.i18n.t('item.count', itemsCount || publicItemsCount || 0),
          ]
          to = { name: privateSearch ? 'setItemsList' : 'setPublic', params: { setId: result.id} }
          icon = 'mdi-google-circles'
          color = 'accent-green'
        } else {
          to = { name: privateSearch ? 'itemViewer' : 'itemPublic', params: { itemId: result.id } }
          icon = 'mdi-google-circles-communities'
          color = 'accent-yellow'
        }

        const _result = {
          result: {
            id: result.id,
            title: result.title,
            description: result.description,
            thumbnail: result.thumbnail,
            chip: this.i18n.t(`${result.docType}.label`),
            subtitles,
          },
          to,
          icon,
          color,
        }
        return _result
      })

      if (results.length) aggregations.filternames.forEach((aggregation) => {
        const name = Object.entries(aggregation)[0][0]
        const filterName = Object.entries(aggregation)[0][1]
        const buckets = aggregations.aggregations[name]
        this.aggregations[filterName] = {
          name,
          filterName,
          buckets: (this.toSearch === query ? this.aggregations[filterName]?.buckets : undefined) ?? buckets.map((bucket) => {
            const id = `${filterName}_${bucket.key}`
            this.buckets[id] = {
              ...bucket,
              value: this.buckets[id]?.value ?? false,
            }
            return id
          }),
        }
        if (!buckets.length) delete this.aggregations[filterName]
      })
      
      this.hits = hits
      
      this.toSearch = query
    },
    setAllBucketsValueToFalse(name: string) {
      this.aggregations[name].buckets.forEach((id) => {
        this.buckets[id].value = false
      })
    }
  },
})
