import { defineStore } from 'pinia'
import { v4 } from 'uuid'
import {
  getLevelNodes,
  getProjectReferentials,
  getReferential,
  patchReferential, patchScope,
  postReferential,
} from '@/services/referentialsService'
import { getPaginatedResult } from './helpers'
import { notifyError } from '@/services/alertService'

import type { PaginationObject } from '#/crud'
import type {
  Hierarchy,
  Node, NodeBase, NodeOption,
  ProjectReferential,
  Referential,
  ReferentialFilters,
  ReferentialState, TemplateReferential,
  Translation,
} from '#/referential'
import { useLocale } from '@/composables/useLocale'
import type { LocaleOption } from '#/user'


const initRef = (): ReferentialState => ({
  referential: {
    name: '',
    description: '',
    definition: {
      levels: [],
      nodes: {},
      roots: [],
    },
    languages: {
      available: [],
      default: '',
      translations: {},
    },
    scope: SCOPE_PROJECT,
  },
  projectReferentials: [],
  projectReferentialsCount: 0,
  pageCount: 1,
  templateReferentials: {}
})

export const SCOPE_PROJECT = 4
export const SCOPE_PUBLIC = 2

export const useReferentialStore = defineStore('Referential', {
  state: () => initRef(),
  getters: {
    availableLanguages: (state) => state.referential.languages.available,
    availableLocales(): LocaleOption[] {
      const { localesArray } = useLocale()
      return localesArray.value.filter(({ value }) => this.availableLanguages.includes(value))
    },
    defaultLanguage: (state): string | null => state.referential.languages.default,
    levelCount: (state): number => state.referential.definition.levels.length,
    isShared: (state): boolean => state.referential.scope < 4,
    levelFromId: (state) => (levelId: string): Hierarchy | undefined => state.referential.definition.levels.find(l => l.id === levelId) ,
    levels: (state): Hierarchy[] => state.referential.definition.levels,
    nodes: (state): Record<string, Node> => state.referential.definition.nodes,
    node(state) {
      return (nodeId: string): Node => state.referential.definition.nodes[nodeId]
    },
    getNodeIndex(state): (nodeId: string, parentId?: string) => number {
      return (nodeId, parentId) => {
        console.log(nodeId)
        console.log(parentId)
        if (parentId) {
          return state.referential.definition.nodes[parentId].children!.findIndex((node) => node === nodeId)
        }
        else {
          return state.referential.definition.roots.findIndex((node) => node === nodeId)
        }
      }
    },
    rootLevelName: (state): string => {
      const levels = state.referential.definition.levels
      return levels.length ? levels[0].name : ''
    },
    rootNodes: (state): Node[] => state.referential.definition.roots.map(id => state.referential.definition.nodes[id]),
    getChildren: (state) => (nodeId: string): string[] => {
      const node = state.referential.definition.nodes[nodeId]
      return node.children
          ? node.children
          : []
    },
    getTemplateReferential: (state) => (referentialId: string | undefined): Referential | undefined  => {
      if (referentialId) return state.templateReferentials[referentialId]
    },
    itemTranslations: (state) => (id: string): Translation => state.referential.languages.translations[id],

    getTranslationFor: (state) => (id: string, langCode: string, defaultName: string): string => {
      const itemTranslations = state.referential.languages.translations[id]
      if (itemTranslations && itemTranslations[langCode]) {
        return itemTranslations[langCode]
      }
      return defaultName
    },
    isTranslated(): boolean {
      return Boolean(this.availableLocales.length)
    },
    isLastNode(state): (index: number, parentId?: string) => boolean {
      return (index, parentId): boolean => {
        if (parentId) {
          return index === this.getChildren(parentId).length - 1
        } else {
          return index === state.referential.definition.roots.length - 1
        }
      }
    },
    needFirstNode(): boolean {
      return this.levelCount === 1 && !this.rootNodes.length
    },
  },
  actions: {
    levelAdd(name: string) {
      this.referential.definition.levels.push({name, id: v4()})
    },
    levelDelete(levelId: string) {
      const levelIndex = this.referential.definition.levels.findIndex(level => level.id === levelId)
      if (levelIndex === 0) {
        const newRoots: string[] = []
        for (const rootId of this.referential.definition.roots) {
          const {children} = this.referential.definition.nodes[rootId]
          if (children?.length) {
            children.forEach(childId => {
              const child = this.referential.definition.nodes[childId]
              if (child.level === 1) {
                newRoots.push(childId)
                child.parents = []
              } else {
                this.nodeDelete(childId, false)
              }
            })
          }
          delete this.referential.definition.nodes[rootId]
        }
        for (const node of Object.values(this.nodes)) {
          node.level --
        }
        this.referential.definition.roots = newRoots
        this.referential.definition.levels = this.levels.slice(1)
      }
      else if (levelIndex > 0) {
        this.referential.definition.levels.splice(levelIndex, 1)
        const cleanNode = (nodeId: string) => {
          const node = this.referential.definition.nodes[nodeId]
          if (node.level === levelIndex) { // give its children to its parents before removing node
            (node.parents as string[]).forEach(parentId => {
              const parent = this.referential.definition.nodes[parentId] as Required<Node>
              const childrenIds = node.children || []
              parent.children.splice(parent.children.indexOf(node.id), 1, ...childrenIds)

              childrenIds.forEach(childId => {
                const child = this.referential.definition.nodes[childId] as Required<Node>
                child.parents.splice(child.parents?.indexOf(nodeId), 1 , parentId)
              })
            })
            delete this.referential.definition.nodes[nodeId]
          }
          else if (node.level < levelIndex && node.children?.length) {
            for (const childId of [...node.children]) {
              cleanNode(childId)
            }
          }
        }
        this.referential.definition.roots.forEach(rootId => {
          const root = this.referential.definition.nodes[rootId]
          if (root.children) {
            root.children.forEach(childId => {
              cleanNode(childId)
            })
          }
        })
        for (const node of Object.values(this.referential.definition.nodes)) {
          if (node.level > levelIndex) {
            node.level--
          }
        }
      }
    },
    levelEdit(levelId: string, patch: Partial<Hierarchy>) {
      const levelIndex = this.referential.definition.levels.findIndex(l => l.id === levelId)
      const updatedLevel = this.referential.definition.levels[levelIndex]
      this.referential.definition.levels[levelIndex] = {...updatedLevel, ...patch}
    },
    async levelNodesLoad(referentialId: string, levelId: string, locale: string, parent?: string) {
      const nodes: NodeBase[] = await getLevelNodes(referentialId, levelId, locale, parent)
      const referential: TemplateReferential = this.templateReferentials[referentialId] || {levelOptions: {}}
      referential.levelOptions[levelId] = nodes.map(
          ({id, label, parents}) => ({label, value: id, parents})
      )
      this.templateReferentials[referentialId] = referential
    },
    nodeAdd(name: string, level = 0, parentId?: string) {
      const newNode = this.nodeCreate(name, level)
      if (parentId) {
        newNode.parents!.push(parentId)
        this.referential.definition.nodes[parentId].children = [
          ...this.referential.definition.nodes[parentId].children ?? [],
          newNode.id
        ]
      }
      else {
        this.referential.definition.roots.push(newNode.id)
      }
    },
    insertNode(name: string, index = 0, level = 0, parentId?: string) {
      const newNode = this.nodeCreate(name, level)
      if (parentId) {
        newNode.parents!.push(parentId)
        this.referential.definition.nodes[parentId].children!.splice(index, 0, newNode.id)
      } else {
        this.referential.definition.roots.splice(index, 0, newNode.id)
      }
    },
    nodeCreate(name: string, level: number): Node {
      const nodeId = v4()
      const newNode = {
        id: nodeId,
        name,
        level,
        parents: []
      };
      this.referential.definition.nodes[nodeId] = newNode
      return newNode
    },
    nodeDelete(nodeId: string, parentReview = true) {
      const { children, parents, level } = this.referential.definition.nodes[nodeId]
      delete this.referential.definition.nodes[nodeId]
      if (level === 0) {
        const roots = this.referential.definition.roots
        roots.splice(roots.indexOf(nodeId), 1)
      } else if (parentReview && parents?.length) {
        parents.forEach(id => {
          const parent = this.nodes[id] as Required<Node>
          parent.children.splice(parent.children.indexOf(nodeId), 1)
        })
      }
      if (children?.length) {
        children.forEach(childId => {
          this.nodeDelete(childId, false)
        })
      }
    },
    nodeEdit(nodeId: string, patch: Partial<Node>) {
      const updatedBranch = this.referential.definition.nodes[nodeId]
      this.referential.definition.nodes[nodeId] = { ...updatedBranch, ...patch }
    },
    nodeMove(nodeId: string, oldIndex: number, newIndex: number, parentId?: string) {
      const container = (parentId
          ? this.referential.definition.nodes[parentId].children
          : this.referential.definition.roots) as string[]

      container.splice(oldIndex, 1)
      container.splice(newIndex, 0, nodeId)
    },
    async referentialCreate(projectId?: string) {
      this.referential = await postReferential(
          {
            projectId,
            ...this.referential,
          }
      )
    },
    async referentialLoad(referentialId: string) {
      this.referential = await getReferential(referentialId)
    },
    async referentialLoadForTemplate(referentialId: string, locale: string) {
      const referential = await getReferential(referentialId)
      this.referential = referential
      const { getTranslationFor, nodes } = this

        function nodeToLevelOption(node: Node, dispatchedOptions: Array<[string, NodeOption[]]>) {
          dispatchedOptions[node.level][1].push({
            value: node.id,
            label: getTranslationFor(node.id, locale, node.name),
            parents: node.parents
          })
          if (node.children?.length) {
            node.children.forEach(id => {
              nodeToLevelOption(nodes[id], dispatchedOptions)
            })
          }
        }

      const levelOptions: Array<[string, NodeOption[]]> = referential.definition.levels.map((level: Hierarchy) => [level.id, []])
      referential.definition.roots.forEach(rootId => {
        nodeToLevelOption(this.nodes[rootId], levelOptions)
      })
      this.templateReferentials[referential.id] = {
        ...referential,
        levelOptions: Object.fromEntries(levelOptions)
      }
    },
    referentialUpdate() {
      const {
        id,
        name,
        description,
        definition,
        project,
        languages,
        scope
      } = this.referential as Referential
      const projectId = project?.id
      return patchReferential({
        id,
        name,
        description,
        definition,
        projectId,
        languages,
        scope
      })
    },
    async referentialShare(scopeData: {scope: 1 | 2 | 4}) {
      this.referential.scope = await patchScope((this.referential as Referential).id, scopeData)
    },
    setDefaultLanguage(newDefault: string) {
      this.referential.languages.default = newDefault
    },
    async loadProjectReferentials(pagination: PaginationObject, filter: ReferentialFilters, projectId: string): Promise<number> {
      return getProjectReferentials(pagination, filter, projectId)
       .then((paginatedReferentials) => {
         const { pageContent: referentials, pageCount, total } = getPaginatedResult<ProjectReferential>(paginatedReferentials, pagination.page, pagination.rowsPerPage)
         this.projectReferentials = referentials
         this.pageCount = pageCount
         this.projectReferentialsCount = total
         return total
       })
       .catch((error) => {
         notifyError(this.i18n.t('notify.error.set.all'), error)
        return 0
       })
    },
    updateTranslations(id: string, newTranslation: Translation) {
      this.referential.languages.translations[id] = newTranslation
    }
  }
})
