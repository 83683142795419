import type { LocaleOption, Language } from '#/user'
import { useQuasar } from 'quasar'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
// TODO: Store lang in user preferences
// import { useUserStore } from '@/stores/useUserStore'
// import authority from '@/services/AuthService'

export function useLocale() {
  const i18n = useI18n()
  const quasar = useQuasar()
  // TODO: Store lang in user preferences
  // const userStore = useUserStore()

  const locales: Record<Language, LocaleOption> = {
    en: { id: 'en', label: 'English', value: 'en', isActive: i18n.locale.value === 'en' },
    fr: { id: 'fr', label: 'Français', value: 'fr', isActive: i18n.locale.value === 'fr' },
  }

  // Computed
  const localesArray = computed(() => Object.values(locales))

  const setQuasarLocale = async (locale: Language) => {
    if (locale.startsWith('en')) {
      locale += '-US'
    }
    const quasarLang = (await import(`../../node_modules/quasar/lang/${locale}.mjs`)).default
    if (quasarLang) quasar.lang.set(quasarLang)
  }

  const setDefaultQuasarLocale = async () => {
    const language = i18n.locale.value as Language
    setQuasarLocale(locales[language].value)

    // TODO: Store lang in user preferences
    // if (authority.isActive) {
    //   if (!userStore.preferences.isLoaded) await userStore.updateUserPreferences({ language })
    //   const userLanguage = userStore.preferences.data.language
    //   await setQuasarLocale(locales[userLanguage].value)
    // } else {
    //   await setQuasarLocale(locales[language].value)
    // }
  }

  const setLocale = async (localeId: Language/*, mustUpdate = true*/) => {
    i18n.locale.value = localeId
    await setQuasarLocale(locales[localeId].value)
    localStorage.setItem('pountLocale', localeId)
    // TODO: Store lang in user preferences
    // if (mustUpdate && authority.isActive) await userStore.updateUserPreferences({ language: localeId })
  }

  return {
    locales,
    localesArray,
    setDefaultQuasarLocale,
    setLocale,
  }
}
