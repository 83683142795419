import { getProjectTemplates, getProjectTemplatesList } from '@/services/projectsService'
import { deleteTemplate, getTemplate, getTemplateCsv, getTemplates, patchTemplate, postTemplate } from '@/services/templatesService'
import { defineStore } from 'pinia'
import { useProjectStore } from './project'
import { notifyError, notifySuccess } from '@/services/alertService'
import { t } from '@/plugins/i18n'
import type { PountTemplate, TemplateState, UsfField } from '#/template'


/**
 * @typedef {Object} UsfFieldOption
 * @property {string} id
 * @property {string} label
 * @property {string} owner
 * @property {string} value
 */


/**
 * @typedef {Object} UsfField
 * @property {string} id -
 * @property {string} name -
 * @property {string} component - "Usf" prefixed form builder name
 * @property {boolean} exist -
 * @property {boolean} visible -
 * @property {string} parentId - parent id or "root"
 * @property {UsfField[]} [fields] - layout children
 * @property {UsfFieldOption[]} [options]
 * @property {boolean} [repeatable] -
 * @property {boolean} [maxRepeat] -
 * @property {boolean} [multiple] -
 * @property {boolean} [required] -
 * @property {boolean} [vertical] - row direction
 * @property {boolean} [width] - col width
 */


/**
 * @typedef PountTemplate
 * @property {string} id
 * @property {string} name
 * @property {number} scope
 * @property {Object | null} creator
 * @property {Object} [project]
 * @property {UsfField[]} fields
 * @property {Object} messages
 * @property {String} [createdAt]
 * @property {String} [updatedAt]
 */

/**
 * Enum for template scope
 * @readonly
 * @enum {number}
 */
const TEMPLATE_SCOPE = Object.freeze({
  BASE: 1,
  GLOBAL: 2,
  PROJECT: 3,
  SET: 4,
})

const LAYOUT_COMPONENTS: Array<UsfField['component']> = [
  'UsfAccordion',
  'UsfCol',
  'UsfContent',
  'UsfPanel',
  'UsfRow',
  'ReferentialComponent',
]

const getPrivateFields = (fields: UsfField[], privateParent=false) => {
  return fields.reduce((privatesFields: string[], field: UsfField) => {
    const isPrivate = Boolean(field.name) && (privateParent || !field.visible || !field.exist)
    if (field.fields) {
      privatesFields.push(...getPrivateFields(field.fields, isPrivate))
    }
    if (isPrivate) {
      privatesFields.push(field.id as string)
    }
    return privatesFields
  }, [])
}

const getInputFields = (fields: UsfField[]): UsfField[] => {
  return fields.reduce((named: UsfField[], field) => {
    if (field.name) {
      named.push(field)
    }
    if (field.fields) {
      named.push(...getInputFields(field.fields))
    }
    return named
  }, [])
}


export { TEMPLATE_SCOPE, LAYOUT_COMPONENTS, getPrivateFields }

const initTemplate = (): PountTemplate => ({
  id: '',
  name: '',
  scope: TEMPLATE_SCOPE.SET,
  fields: [],
  creator: null,
  messages: {
    defaultLocale: 'fr',
    locales: {
      fr: {}
    }
  },
  privateFields: [],
})

export const useTemplateStore = defineStore('Template', {
  state: (): TemplateState => ({
    /** Template of set, for visualisation or edition */
    current: initTemplate(),
    /** List templates of scope BASE or GLOBAL */
    publicList: [],
    /** List all templates of current project, at project or set level */
    projectList: [],
    /** Template select options for set creation */
    templateList: [],
  }),
  getters: {
    inputFields: (state) => getInputFields(state.current.fields),
  },
  actions: {
    setCurrentTemplate(template: PountTemplate) {
      this.current = template
    },
    loadTemplate(templateId: string) {
      return getTemplate(templateId)
        .then((template) => {
          this.setCurrentTemplate(template)
        })
        .catch((error) => {
          notifyError(
            t('notify.error.template.load'),
            error
          )
        })
    },
    /**
     * Create a new template for current project,
     * and add it to project's template list
     */
    createTemplate(template: Pick<PountTemplate, 'name' | 'fields'| 'messages'>) {
      const projectStore = useProjectStore()
      const templateData = {
        projectId: projectStore.projectId,
        scope: TEMPLATE_SCOPE.PROJECT,
        ...template,
        privateFields: getPrivateFields(template.fields)
      }
      return postTemplate(templateData)
        .then((template) => {
          this.projectList = [ ...this.projectList, template]
          return template.id
        })
        .catch((error) => {
          notifyError(
            t('notify.error.template.create'),
            error
          )
        })
    },
    /**
     * Patch template data,
     * and update its store instance in project list
     * or the current template for a set
     */
    updateTemplate(template: Partial<PountTemplate>) {
      const {id, fields, ...templatePatch} = template
      const privateFields = getPrivateFields(fields as UsfField[])
      return patchTemplate(id as string, { ...templatePatch, fields, privateFields })
        .then((template) => {
          this.setCurrentTemplate(template)
          notifySuccess(t('notify.updated.template'))
        })
        .catch((error) => {
          notifyError(
              t('notify.error.update'),
              error
          )
        })
    },
    /**
     * Retrieve all shared templates
     */
    loadPublicTemplates() {
      return getTemplates()
        .then((templates) => {
          this.publicList = templates
        })
        .catch((error) => {
          notifyError(
              t('notify.error.template.all'),
              error
          )
        })
    },
    /**
     * export current template as csv
     */
    exportCsv() {
      const templateId = this.current.id
      return getTemplateCsv(templateId)
        .then((templateCsv) => {
          const blob = new Blob([templateCsv], { type: 'application/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'template.csv'
          link.click()
        })
    },
    /**
     * Retrieve all templates of current project in store
     */
    loadProjectTemplates() {
      const projectStore = useProjectStore()
      const projectId = projectStore.projectId
      return getProjectTemplates(projectId)
        .then((templates) => {
          this.projectList = templates
        })
        .catch((error) => {
          notifyError(
              t('notify.error.template.all'),
              error
          )
        })
    },
    /**
     * Retrieve all available templates,
     * to be cloned at set creation
     */
    loadTemplatesList() {
      const projectStore = useProjectStore()
      const projectId = projectStore.projectId
      return getProjectTemplatesList(projectId)
        .then((templates) => {
          this.templateList = templates
        })
        .catch((error) => {
          notifyError(
              t('notify.error.template.all'),
              error
          )
        })
    },
    deleteMultiTemplate(templateIds: string[]) {
      return Promise
        .allSettled(templateIds.map(deleteTemplate))
        .then((values) => {
          type TemplateDeleteResults = {
            success: PromiseFulfilledResult<void>[];
            errors: PromiseRejectedResult[];
          }
          const { success, errors } = values.reduce<TemplateDeleteResults>((acc, current): TemplateDeleteResults => {
            if (current.status === 'fulfilled') acc.success.push(current)
            else if (current.status === 'rejected') acc.errors.push(current)
            return acc
          }, { success: [], errors: [] })

          if (errors.length) notifyError(this.i18n.t(`notify.error.template.delete${errors.length === templateIds.length ? 'All' : ''}`, errors.length), new Error())
          else notifySuccess(this.i18n.t('notify.success.template.delete', success.length))
        })
    },
  },
})
