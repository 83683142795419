import { defineStore } from 'pinia'
import {
  deleteProjectThumbnail,
  getProject,
  getProjects,
  patchProject,
  postProject,
  postProjectThumbnail,
  requestProjectsDeletion
} from '@/services/projectsService'
import { getPaginatedResult } from '@/stores/helpers'
import { t } from '@/plugins/i18n'
import { notifyError, notifySuccess } from '@/services/alertService'

import type { NewPountProject, PatchPountProject, PountProject, ProjectStoreSate } from '#/project'
import type { GroupUser } from '#/group'


export const initProject = (): PountProject => ({
  id: '0',
  name: '',
  description: '',
  isManager: false,
  isPublic: false,
  info: {},
  managers: [],
  members: [],
  thumbnails: {},
})

export const useProjectStore = defineStore('Project', {
  state: (): ProjectStoreSate => ({
    /** Project of current view */
    current: initProject(),
    /** Public project list */
    public: [],
    /** Project list whose user is member */
    contributions: [],
    contributionCount: 0,
    pageCount: 1,
  }),
  getters: {
    projectId: (state) => state.current.id,
    isManager: (state) => state.current.isManager,
    projectMembers: (state) => state.current.members,
  },
  actions: {
    updateCurrentManagers(managers: GroupUser[]) {
      this.current.managers = managers
    },
    createProject(project: NewPountProject) {
      return postProject(project)
        .then((createdProject) => {
          this.current = createdProject
        })
        .catch((error) => {
          notifyError(t('notify.error.project.create'), error)
        })
    },
    loadProject(projectId: string) {
      return getProject(projectId)
        .then((project) => {
          this.current = project
        })
        .catch((error) => {
          notifyError(t('notify.error.project.load'), error)
        })
    },
    /**
     * send email to referent to ask for projects deletion,
     * projects are unpublished
     */
    requestDeletion(projectIds: string[]) {
      return requestProjectsDeletion(projectIds)
          .then(() => {
            notifySuccess(t('notify.delete.request.success'))
          })
          .catch((error) => {
            notifyError(t('notify.delete.request.error'), error)
          })
    },
    retrieveProjects(page = 1, size = 0, manageOnly = false) {
      return getProjects(page, size, manageOnly)
          .then((paginatedProjects) => {
            const {pageContent, pageCount, total} = getPaginatedResult<PountProject>(paginatedProjects, page, size)
            this.contributions = pageContent
            this.pageCount = pageCount
            this.contributionCount = total
            return total
          })
          .catch((error) => {
            notifyError(t('notify.error.project.all'), error)
          })
    },
    updateProject(project: PatchPountProject) {
      return patchProject(project)
        .then((patchedProject) => {
          this.current = patchedProject
          notifySuccess(t('project.generalInfo.updated'))
        })
        .catch((error) => {
          notifyError(t('notify.error.update'), error)
        })
    },
    async thumbnailUpdate(objectId: string, file: File) {
      const { name: filename, type } = file
      const formData = new FormData()
      formData.append("Content-Type", type)
      formData.append("file", file)
      await postProjectThumbnail(objectId, formData).then((thumbnails) => {
        this.current.thumbnails = thumbnails
        notifySuccess(t('file.upload.success', { filename }))
      })
      .catch((error) => {
        notifyError(t('file.upload.error'), error)
      })
    },
    thumbnailDelete(objectId: string) {
      return deleteProjectThumbnail(objectId)
        .then(() => {
          this.current.thumbnails = {};
          notifySuccess(t('file.delete.success'));
        })
        .catch((error) => {
          notifyError(t('file.delete.error'), error)
        })
    },
  },
})
