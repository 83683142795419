<script setup lang="ts">
import { useQuasar } from 'quasar'

const quasar = useQuasar()

// Props
defineProps<{
  icon: string;
  accessLabel: string;
}>()
</script>

<template>
  <q-btn
    color="primary"
    flat
    dense
    :aria-label="accessLabel"
  >
    <div class="row item-center no-wrap">
      <q-icon
        :name="icon"
        size="20px"
      />
      <q-icon
        name="mdi-menu-down"
        size="20px"
        style="margin-left: -2px"
      />
    </div>
    <q-menu
      auto-close
      square
      :class="{
        'bg-elevation-8': quasar.dark.isActive
      }"
    >
      <q-list
        style="min-width: 200px"
      >
        <slot />
      </q-list>
    </q-menu>
  </q-btn>
</template>
