import { createI18n } from 'vue-i18n'
import { en } from '@/locales/en'
import { fr } from '@/locales/fr'

const appLocale = import.meta.env.VITE_APP_I18N_LOCALE

const locale = import.meta.env.NODE_ENV === 'test'
  ? appLocale
  : localStorage.getItem('pountLocale') || navigator.language.split('-')[0] || appLocale

const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: appLocale,
  messages: {
    fr,
    en,
  },
})

const t = i18n.global.t

export {i18n, t}

export default i18n
