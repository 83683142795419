import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { authAxios } from '@/services/authService'
import { useContribStore } from '@/stores/contrib'
import { useProjectStore } from '@/stores/project'
import { useSetStore } from '@/stores/set'
import { useItemStore } from '@/stores/item'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

if (import.meta.env.NODE_ENV !== 'test') {
  router.beforeEach(async (to, from) => {
    const shielded = to.matched.some((route) => route.meta.requiresAuthentication)
    const hasValidToken = authAxios.isActive
    if (hasValidToken) {
      const contribStore = useContribStore()
      await contribStore.loadProfile()
    }
    if (shielded) {
      if (!hasValidToken) {
        return {name: 'login', params: {from: from.fullPath}}
      }
      else {
        const managerOnly = to.matched.some((route) => route.meta.managerOnly)
        if (managerOnly) {
          const context = to.matched[0].meta.context as 'project' | 'set' | 'item'
          if (context === 'item') {
            const itemId = to.params.itemId.toString();
            const store = useItemStore()
            if (store.itemId !== itemId) {
              await store.itemLoad(itemId)
            }
            if (!store.canEdit) {
              const objectId = to.params[`${context}Id`];
              return `/${context}s/${objectId}/viewer`
            }
            
          }
          else {
            const store = {
              project: useProjectStore,
              set: useSetStore,
            }[context]()
            const isManager = store.isManager
            if (!isManager) {
              const objectId = to.params[`${context}Id`];
              const children = {
                project: 'sets',
                set: 'items',
              }[context]
              return `/${context}s/${objectId}/${children}`
            }
          }
        }
      }
    }
  })
}

export default router
