import { defineStore } from 'pinia'

import { getPaginatedResult } from '@/stores/helpers'
import { useProjectStore } from './project'
import {
  deleteSet,
  deleteSets,
  deleteSetThumbnail,
  getProjectSets,
  getSet,
  patchSet,
  postSet,
  postSetThumbnail
} from '@/services/setsService'
import { notifyError, notifySuccess } from '@/services/alertService'
import { t } from '@/plugins/i18n'

import type { BuildingSet, PountSet, ProjectSet, SetPatch, SetStoreState } from '#/set'

export const initSet = (): PountSet => ({
  canContribute: false,
  creator: {id: '', name: '', email: ''},
  description: '',
  id: '',
  isPublic: false,
  numItems: false,
  numPublicItems: false,
  project: {id: '0', name: '', isManager: false, isPublic: false}, // init before data load to provide link
  publisher: '',
  template: {id: '0', name: ''},
  thumbnails: {},
  title: '',
  updatedAt: '',
})

export const useSetStore = defineStore('Set', {
  state: (): SetStoreState => ({
    current: initSet(),
    projectSets: [],
    projectSetsCount: 0,
    pageCount: 1,
  }),
  getters: {
    // publicList: state => state.publicList,
    setId: (state) => state.current.id,
    isManager: (state) => state.current.project.isManager,
    canContribute: (state) => state.current.canContribute,
    templateId: (state) => state.current.template.id,
  },
  actions: {
    setCurrentSet(set: PountSet) {
      this.current = { ...set}
    },
    /** Create a Set for current project and load it as current set. */
    createSet(set: BuildingSet) {
      const projectStore = useProjectStore()
      const { projectId } = projectStore
      const setData = {
        ...set,
        projectId,
      }
      return postSet(setData)
        .then((createdSet: PountSet) => {
          this.setCurrentSet(createdSet)
        })
    },
    /** Retrieve from api set of given id and load it into current set. */
    loadSet(setId: string) {
      return getSet(setId)
        .then((set) => {
          this.setCurrentSet(set)
        })
        .catch((error) => {
          notifyError(t('notify.error.set.load'), error)
        })
    },
    loadProjectSets(page = 1, size = 0, projectId?: string) {
      const projectStore = useProjectStore()
      const currentProjectId = projectStore.projectId
      const projectFilter = projectId || currentProjectId
      return getProjectSets(page, size, projectFilter)
          .then((paginatedSets) => {
            const { pageContent: sets, pageCount, total } = getPaginatedResult<ProjectSet>(paginatedSets, page, size)
            this.projectSets = sets
            this.pageCount = pageCount
            this.projectSetsCount = total
            return total
          })
          .catch((error) => {
            notifyError(t('notify.error.set.all'), error)
          })

    },
    /**
     * delete set of given id
     *
     * @param {string} setId
     * @returns {Promise<*>}
     */
    deleteSet(setId: string) {
      return deleteSet(setId)
        .then(() => {
          this.setCurrentSet(initSet())
          notifySuccess(t('notify.delete.success'))
        })
        .catch((error) => {
          notifyError(t('notify.error.set.delete', 1), error)
        })
    },
    /** delete a list of set of given ids */
    deleteMultiSet(setIds: string[]) {
      return deleteSets(setIds)
        .then(() => {
          this.projectSets = this.projectSets.filter((set) => !setIds.includes(set.id))
          notifySuccess(t('notify.delete.success'))
        })
        .catch((error) => {
          notifyError(t('notify.error.set.delete', setIds.length), error)
        })
    },
    /** Update current set fields, one or many among title, description and publisher */
    updateSet(setPatch: SetPatch) {
      const setId = this.current.id as string
      return patchSet(setId, setPatch)
        .then((set) => {
          this.setCurrentSet(set)
        })
    },
    async thumbnailUpdate(objectId: string, file: File) {
      const { name: filename } = file
      const formData = new FormData()
      formData.append("Content-Type", file.type)
      formData.append("file", file)
      await postSetThumbnail(objectId, formData)
          .then((thumbnails) => {
            this.current.thumbnails = thumbnails
            notifySuccess(t('file.upload.success', { filename }))
          })
          .catch((error) => {
            notifyError(t('file.upload.error', {filename}), error)
          })
    },
    thumbnailDelete(objectId: string) {
      return deleteSetThumbnail(objectId)
        .then(() => {
          this.current.thumbnails = {};
          notifySuccess(t('file.delete.success'))
        })
        .catch((error) => {
          notifyError(t('file.delete.error'), error)
        })
    },
  },
})
