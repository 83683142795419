<script setup lang="ts">
import { useAppearance } from '@/composables/useAppearance'

const { modesArray, setMode } = useAppearance()
</script>

<template>
  <q-item
    v-for="(mode, index) in modesArray"
    :key="index"
    clickable
    :aria-selected="mode.isActive"
    @click="setMode(mode.id)"
    v-close-popup
  >
    <q-item-section avatar>
      <q-icon
        v-if="mode.isActive"
        name="mdi-check"
      />
    </q-item-section>
    <q-item-section>{{ mode.label }}</q-item-section>
  </q-item>
</template>
