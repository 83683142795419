import { publicAxios, authAxios } from '@/services/authService'
import type { PountTemplate } from '#/template'

export const getPublicTemplate = async (templateId: string) => {
  const response = await publicAxios.get(`template/${templateId}/`)

  return response.data
}

export const getTemplate = async (templateId: string) => {
  const response = await authAxios.get(`templates/${templateId}/`)

  return response.data
}

export const getTemplateCsv = async (templateId: string) => {
  const response = await authAxios.get(`templates/${templateId}/export_as_csv`, { responseType: 'arraybuffer' })

  return response.data
}

export const getTemplates = async () => {
  const response = await authAxios.get('templates/')

  return response.data
}

export const postTemplate = async (templateData: Omit<PountTemplate, 'id' | 'creator'>) => {
  const response = await authAxios.post(`templates/`, templateData)

  return response.data
}

export const patchTemplate = async (templateId: string, templatePath: Partial<PountTemplate>) => {
  const response = await authAxios.patch(`templates/${templateId}/`, templatePath)

  return response.data
}

export const deleteTemplate = async (templateId: string) => {
  await authAxios.delete(`templates/${templateId}/`)
}
