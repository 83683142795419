<template>
  <q-layout>
    <q-page-container>
      <slot />
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'ULayoutWithout',
};
</script>
