<script setup lang="ts">
import { computed } from 'vue'
import { useQuasar } from 'quasar'

const quasar = useQuasar()

const isDark = computed(() => quasar.dark.isActive)
const icons = computed(() => ({ content: ['us-share'] }))
</script>

<template>
  <div
    class="sun sun-gray-darker signature signature__mobile"
    :class="isDark ? 'signature__dark' : ''"
  >
    <span class="sun-row">
      <router-link
        class="sun-cell"
        :to="{ name: 'Home' }"
      >
        <span class="sun-cell-inner">
          <i
            v-for="(icon, index) in icons.content"
            :key="`signature-icon-${index}`"
            class="us"
            :class="icon"
          />
        </span>
      </router-link>
      <router-link
        class="sun-cell"
        :to="{ name: 'Home' }"
      >
        <span class="sun-cell-inner">{{ $t('signature.short') }}</span>
      </router-link>
    </span>
  </div>
</template>
