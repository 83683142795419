import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
// @ts-ignore external library
import VueMatomo from 'vue-matomo'

import { Notify, Quasar } from 'quasar'
import quasarIconSet from 'quasar/icon-set/svg-mdi-v6'
import '@quasar/extras/mdi-v6/mdi-v6.css'
import 'quasar/src/css/index.sass'

import { FormbuilderPlugin } from '@vue-unistra/formbuilder'
import '@vue-unistra/formbuilder/style.css'

import App from './App.vue'
import IconButton from '@/components/common/IconButton.vue'
import i18n from '@/plugins/i18n'
import router from './router'
import '@vue-unistra/hopla/dist/style.css'
import { authAxios as authService } from '@/services/authService'
import { layouts } from '@/layouts'
import filters from '@/filters'

import type { Router } from 'vue-router'
import type { Composer } from 'vue-i18n'


const app = createApp(App)

declare module 'vue' {
  interface ComponentCustomProperties {
    $filters: typeof filters
  }
}
app.config.globalProperties.$filters = filters

declare module 'pinia' {
  export interface PiniaCustomProperties {
      router: Router
      i18n: Composer
  }
}
const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
  store.i18n = markRaw(i18n).global as Composer
})

if (import.meta.env.VITE_APP_SENTRY_TAG) {
  Sentry.init({
    app,
    dsn: 'https://113c189e34e544f495924e19e18924d7@sentry.app.unistra.fr/8',
    release: __SENTRY_RELEASE__,
    environment: import.meta.env.VITE_APP_SENTRY_TAG,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', import.meta.env.VITE_APP_FRONT_URL, /^\//],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    debug: true,
  })
}

if (import.meta.env.VITE_APP_MATOMO_SERVER && import.meta.env.VITE_APP_MATOMO_SITE_ID) {
  app.use(VueMatomo, {
    router,
    host: import.meta.env.VITE_APP_MATOMO_SERVER,
    siteId: import.meta.env.VITE_APP_MATOMO_SITE_ID,
    trackerFileName: 'm',
    debug: import.meta.env.VITE_APP_MATOMO_DEBUG
  })
}

app.use(i18n)
app.use(pinia)
app.use(router)
//@ts-ignore
app.use(Quasar, {
  iconSet: quasarIconSet,
  plugins: {
    Notify
  },
})

// @ts-ignore plugin type issue
app.use(FormbuilderPlugin)


// declare global components
app.component('i-button', IconButton)
layouts.forEach((layout) => { app.component(layout.name, layout) })

authService
  .initToken()
  .finally(() => {
    app.mount('#app')
  })
